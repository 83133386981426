import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import TermsAndConditionReceipt from "./TermsAndCondition";
import KFSReceipt from "./KFS";
import { ConditionalRendering } from "../../../utils/methods";

const ReceiptModalContainer = ({ isOpen, setIsOpen, loading, employee, advanceSalaryDetails, applyForAdvanceSalary }) => {

    const feesBrackets = advanceSalaryDetails?.feesBrackets;

    const [page, setPage] = useState('1');

    useEffect(() => {
        if (isOpen) {
            setPage('1');
        }
    }, [isOpen]);

    const isFirstPage = () => page === '1';
    const isSecondPage = () => page === '2';
    const conditionalText = isFirstPage() ? 'details' : 'terms and conditions';

    const onClickOK = () => {
        if (isFirstPage()) {
            setPage('2');
        } else if (isSecondPage()) {
            setIsOpen(false);
            applyForAdvanceSalary(true);
        }
    }

    return <>
        <Modal
            title=""
            width={1200}
            centered
            open={isOpen}
            destroyOnClose={true}
            maskClosable={false}
            onCancel={() => !loading && setIsOpen(false)}
            okButtonProps={{ onClick: onClickOK, loading: loading }}
            okText={`Yes, I have read and understood the ${conditionalText}`}
            cancelButtonProps={{ hidden: true }}
        >

            <ConditionalRendering
                condition={isFirstPage()}
                children={<>
                    <KFSReceipt employee={employee} feesBrackets={feesBrackets} />
                </>}
            />

            <ConditionalRendering
                condition={isSecondPage()}
                children={<>
                    <TermsAndConditionReceipt />
                </>}
            />

        </Modal>
    </>
};

export default ReceiptModalContainer;