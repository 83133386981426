import React from "react";
import { message, Input, Empty, Radio, Checkbox, Image as ImageAntd, notification } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ACTION, APPROVAL_MESSAGES_ARRAY, DOTNET_CARD_STATUS, TOPIC, WHITELIST_BLACKLIST } from "./constants";
import dayjs from "dayjs";
import _ from "lodash";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { countryData } from "./countryData";
import { Card, Skeleton, Statistic } from "antd";
import { CButton, CField } from "../uiComponents";
import { AiFillFilter, AiOutlineEye } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { CgCalendarDates } from "react-icons/cg";
import { BsCalendar4Range } from "react-icons/bs"
import { CHECKBOX, RADIO } from "./constants";
import { PhoneNumberUtil } from 'google-libphonenumber';
import { TOKEN } from "./asyncStorage/Constants";
import { getValueIntoLocalStorage } from "./asyncStorage/Functions";
import jwtDecode from "jwt-decode";

export const encodeQueryData = (data) => {
  const ret = [];
  for (const d in data) {
    ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
  }
  return ret.join("&");
};

export const toast = (type, text) => {
  if (text) {
    if (type === "success") {
      message.success(text);
    } else if (type === "warning") {
      message.warning(text);
    } else if (type === "info") {
      message.warning(text);
    } else if (type === "error") {
      message.error(text);
    }
  }
};

export const handleCommon = (type, title, description, otherOptions) => {
  // Toast.show({
  //     type: type,
  //     text1: title,
  //     text2: description || '',
  //     ...otherOptions
  // });
};
export const handleInfo = (text, defaultDescriptions, otherOptions) => {
  message.info(text)
};
export const handleError = (text, defaultDescription, otherOptions) => {
  // Toast.show({
  //     type: 'error',
  //     text1: 'Error',
  //     text2: description || 'Something went wrong!',
  //     topOffset: Platform.OS === 'ios' ? 55 : 15,
  //     ...otherOptions
  // });

  message.error(text);
};
export const handleSuccess = (text, defaultDescription = "", otherOptions) => {
  message.success(text);

  // Toast.show({
  //     type: 'success',
  //     text1: 'Successfully',
  //     text2: message || '',
  //     topOffset: Platform.OS === 'ios' ? 55 : 15,
  //     ...otherOptions
  // });
};

export const MappedElement = ({ data, renderElement, empty }) => {
  if (data && data.length) {
    return data.map((obj, index, array) => renderElement(obj, index, array));
  }
  return empty ? empty() : null;
};

export const splitString = (value, idx) =>
  value.split(new RegExp(`(?<=^.{${idx}})`));

export const generateArrayOfString = (array, key = "_id") => {
  let ids = [];
  if (array && array.length) {
    array.forEach((obj) => ids.push(obj[key]));
  }
  return ids;
};

export const convertToSlug = (Text) => {
  return Text
    ? Text.toLowerCase()
      .replace(/ /g, "_")
      .replace(/[^\w-]+/g, "")
    : "";
};

export function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string.slice(1);
}

export const formatter = (value, currency = "AED") => {
  const val = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
  });

  return val.format(value || 0);
};
export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const cards = [
  {
    code: "35",
    type: "PAYD",
    title: "PayD",
  },
  {
    code: "36",
    type: "CENTIV",
    title: "Centiv",
  },
];

export const foundProduct = (code, key) => {
  if (code) {
    return cards.find((o) => {
      if (key) {
        return o[key] === code;
      } else {
        let two_letter = code.toString().substring(0, 2);
        return two_letter === o.code.toString();
      }
    });
  }
  return cards[0];
};

const debit_credit = [
  {
    key: "D",
    value: "Debit",
  },
  {
    key: "C",
    value: "Credit",
  },
];
export const getModeTrans = (mode, key = "key") => {
  if (mode) {
    return debit_credit.find((o) => {
      if (key) {
        return o[key] === mode;
      } else {
        return mode.toString().includes(o.key);
      }
    });
  }
  return debit_credit[0];
};

export function parseQuery(queryString) {
  let query = {};
  let pairs = (
    queryString[0] === "?" ? queryString.substr(1) : queryString
  ).split("&");
  for (let i = 0; i < pairs.length; i++) {
    let pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
}

export const getQueryValue = (name, parsedQuery, atZero = false) => {
  if (atZero) {
    return parsedQuery[name] ? parsedQuery[name] : "";
  }
  return parsedQuery[name] ? getArrayElementsFromQuery(parsedQuery[name]) : "";
};

export const getArrayElementsFromQuery = (array) => {
  return array ? array.split(",") : [];
};

const queryStringToObject = url => [...new URLSearchParams(url.split('?')[1])].reduce((a, [k, v]) => ((a[k] = v), a), {});

export const getQueryFiltersAsObject = (location, allFilter = false, withPagination = true) => {
  const pQuery = parseQuery(location.search);
  var filters = {};

  let obj;
  if (allFilter) {
    obj = queryStringToObject(location.search);
    if (!withPagination) {
      obj = _.omit(obj, ['page', 'limit']);
    }

    const dateFormatPattern = /^[A-Za-z]{3} [A-Za-z]{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT[+-]\d{4}$/;
    const dateFormatPattern2 = /^[A-Za-z]{3}, \d{2} [A-Za-z]{3} \d{4} \d{2}:\d{2}:\d{2} GMT$/;

    Object.keys(obj).forEach((item) => {
      if (dateFormatPattern.test(obj[item]) || dateFormatPattern2.test(obj[item])) {
        obj[item] = dayjs(obj[item]).toISOString();
      }
    });

    filters = {
      ...obj
    };

    if (withPagination) {
      if (obj?.page) {
        filters.page = parseInt(obj?.page);
      }
      if (obj?.limit) {
        filters.limit = parseInt(obj?.limit);
      }
    }

  } else {
    if (getQueryValue("to", pQuery)) {
      filters.to = dayjs(String(getQueryValue("to", pQuery)));
    }
    if (getQueryValue("from", pQuery)) {
      filters.from = dayjs(String(getQueryValue("from", pQuery)));
    }
    if (getQueryValue("expiredEid", pQuery)) {
      filters.expiredEid = getQueryValue("expiredEid", pQuery, true);
    }
    if (getQueryValue("cardType", pQuery)) {
      filters.cardType = String(getQueryValue("cardType", pQuery, true));
    }
    if (getQueryValue("isApplicationBlocked", pQuery)) {
      filters.isApplicationBlocked = getQueryValue("isApplicationBlocked", pQuery, true);
    }
    if (getQueryValue("isApplicationUser", pQuery)) {
      filters.isApplicationUser = getQueryValue("isApplicationUser", pQuery, true);
    }
    if (getQueryValue("isActive", pQuery)) {
      filters.isActive = getQueryValue("isActive", pQuery, true);
    }
    if (getQueryValue("approvalTrackingStatus", pQuery)) {
      filters.approvalTrackingStatus = getQueryValue("approvalTrackingStatus", pQuery);
    }
    if (getQueryValue("topic", pQuery)) {
      filters.topic = getQueryValue("topic", pQuery, true);
    }
    if (getQueryValue("selectedType", pQuery)) {
      filters.selectedType = getQueryValue("selectedType", pQuery, true);
    }
    if (getQueryValue("topicsInclude", pQuery)) {
      filters.topicsInclude = getQueryValue("topicsInclude", pQuery);
    }
    if (getQueryValue("exchangeHouse", pQuery)) {
      filters.exchangeHouse = getQueryValue("exchangeHouse", pQuery, true);
    }
    if (getQueryValue("exchangeHouseBranch", pQuery)) {
      filters.exchangeHouseBranch = getQueryValue("exchangeHouseBranch", pQuery, true);
    }
    if (getQueryValue("empId", pQuery)) {
      filters.empId = getQueryValue("empId", pQuery, true);
    }
    if (getQueryValue("client", pQuery)) {
      filters.client = getQueryValue("client", pQuery, true);
    }
    if (getQueryValue("clientStatus", pQuery)) {
      filters.clientStatus = getQueryValue("clientStatus", pQuery, true);
    }
    if (getQueryValue("endOfService", pQuery)) {
      filters.endOfService = getQueryValue("endOfService", pQuery, true);
    }

    // if (getQueryValue("exchangeHouse", pQuery)) {
    //   filters.exchangeHouse = getQueryValue("exchangeHouse", pQuery, true);
    // }
    // // if (getQueryValue("exchangeHouseBranch", pQuery)) {
    // //   filters.exchangeHouseBranch = getQueryValue("exchangeHouseBranch", pQuery, true);
    // // }

    if (withPagination) {
      if (getQueryValue("page", pQuery)) {
        filters.page = parseInt(getQueryValue("page", pQuery));
      }
    }
  }

  return filters;
};

export const pushFiltersToQueryString = (payload, navigate, location) => {
  // if (location) {
  //     let filterObject = { ...getQueryFiltersAsObject(location), ...payload };
  //     navigate(
  //         `${window.location.pathname}?${encodeQueryData(filterObject)}`
  //     );
  // } else {
  navigate(`${window.location.pathname}?${encodeQueryData(payload)}`, {
    replace: true,
    state: location?.state || {},
  });
  // }
};

export const searchView = (searchText, updateSearchText, className = '') => {
  return (
    <div className={`search-view input-container c-input  mb-3 ${className}`}>
      <Input
        placeholder="Search"
        value={searchText || ""}
        onChange={(e) =>
          /^[a-zA-Z0-9\s@.+]*$/.test(e.target?.value) &&
          updateSearchText(e.target.value)
        }
      />
      <SearchOutlined />
    </div>
  );
};

export const renderDateMonthNumber = (date = undefined) => {
  return dayjs(date).format("MM-DD-YYYY");
};
export const renderOptions = (options, key) => {
  if (options && options.length) {
    return options.map((option, index) => (
      <option key={index} value={option._id}>
        {option?.name}
      </option>
    ));
  }
  return null;
};

export const getBase64 = async (file) => {
  try {
    return await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
    });
  } catch (e) {
    console.log(e);
  }
};

export const openFileInBrowser = async (file, isLink, fileName) => {
  try {
    if (file?.type && file?.type.search(/(jpg|jpeg|png|pdf)/) !== -1) {
      let src = file?.url;

      if (!src) {
        src = await getBase64(file);
      }

      let a = window.open("", "_blank");
      a.document.write("<html>");
      a.document.write('<body style="margin: 0" >');
      a.document.write(
        `<embed width="100%" height='100%' src="${src}" type="${file?.type}" ></embed>`
      );
      a.document.write("</body></html>");
      a.document.close();
    } else {
      let url = "";
      if (!isLink) {
        url = window.URL.createObjectURL(new Blob([file || file?.thumbUrl]));
      } else {
        url = file;
      }

      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      link.setAttribute("download", file?.name || fileName || new Date());
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (e) {
    handleError(e);
  }
};

export const downloadImage = async (url, fileName) => {
  try {
    var img = document.createElement("a");
    img.href = url;
    img.download = fileName;
    img.click();
  } catch (e) {
    console.log('error download Image', e)
  }
};

export const uploadFile = (setFiles, accept = "", multiple = false) => {
  const file = document.createElement("input");
  file.style.position = "fixed";
  file.style.top = "-100em";
  file.type = "file";
  file.accept = accept;
  file.multiple = multiple;

  file.addEventListener("change", () => {
    if (file.files) {
      setFiles && setFiles(file.files);
    }
  });

  const teardown = () => {
    document.body.removeEventListener("focus", teardown, true);
    setTimeout(() => {
      document.body.removeChild(file);
    }, 1000);
  };
  document.body.addEventListener("focus", teardown, true);

  document.body.appendChild(file);
  file.click();
};

export const getReverseDate = (date = undefined) => {
  return dayjs(date).format("YYYY-MM-DD").toString();
};

export const isImage = (file) => {
  return file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
};

export const renderDate = (date = undefined) => {
  return dayjs(date).format("DD-MMM-YYYY");
};

export const renderDateAndTime = (date = undefined) => {
  return dayjs(date).format("DD-MMM-YYYY (hh:mm a)");
};

export const renderMonthAndYear = (date = undefined) => {
  return dayjs(date).format("MMM-YYYY");
};

export const renderDateAndTimeFile = (date = undefined) => {
  return dayjs(date).format("YYYY-MMM-DD_HH:mm");
};

export const readableText = (text) => {
  if (text === "DEACTIVE") {
    return "In-Active"

  } else {
    return text?.length ? _.capitalize(text)?.split("_").join(" ") : "";

  }

};

export const readableTextCap = (text) => {
  if (text?.length) {
    let arr = text?.split("_");
    for (let i = 0; i < arr.length; i++) {
      arr[i] = _.capitalize(arr[i]);
    }

    arr = arr?.join(" ");
    return arr;
  }
  return "";
};

export const _readableTextCap = (text) => {
  if (text?.length) {
    let arr = text?.split("_");
    for (let i = 0; i < arr.length; i++) {
      arr[i] = _.capitalize(arr[i]);
    }

    arr = arr?.join(" ");
    return arr;
  }
  return "";
};

export const dataEmpty = (description = "No Data") => {
  return (
    <div className="flex-1 d-flex justify-content-center align-items-center">
      <Empty description={description} image={Empty.PRESENTED_IMAGE_DEFAULT} />
    </div>
  );
};

export const IsAllowedOption = (role = "") => {
  const user = useSelector(({ auth }) => auth.user);
  if (user && Object.keys(user)?.length) {
    if (user?.isSuperAdmin) return true;
    if (user?.roles && Object.keys(user?.roles)?.length) {
      if (!user?.isSuperAdmin)
        return Object.values(user?.roles).some(
          (items) => items && items?.length && items.some((itm) => role === itm)
        );
    }
  }
  return false;
};
export const renderExcelFile = async (file, isLink = false) => {
  try {
    if (file) {
      let response = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => {
          var data = new Uint8Array(reader.result);
          var work_book = XLSX.read(data, { type: "array" });
          var sheet_name = work_book?.SheetNames;
          var sheet_data = XLSX.utils.sheet_to_json(
            work_book.Sheets[sheet_name[0]],
            {
              header: 1,
              defval: "",
              raw: false,
              dateNF: "YYYY-MMM-DD",
              rawNumbers: false,
            }
          );
          resolve(sheet_data);
        };
      });
      return await response;
    }
  } catch (e) {
    console.log("eeeeee", e);
  }
};
export const getObjByKey = (array = [], val = "", key = "_id") => {
  return array?.length ? array?.find((obj) => obj[key] === val) : {};
};
export const firstDayOfMonth = (date) => {
  return dayjs(date).startOf("month");
};
export const lastDayOfMonth = (date) => {
  return dayjs(date).endOf("month");
};

export const renderDocuments = (documents, renderImage) => {
  return (
    <MappedElement
      data={documents}
      empty={() => null}
      renderElement={(item, i) => {
        return (
          <>
            {item?.documentNumber ? (
              <li>
                <span>{item?.name || 'Document'} Number</span>
                <span>{item?.documentNumber}</span>
              </li>
            ) : null}
            {item?.name ? (
              <li>
                <span>Document Name</span>
                <span>{item?.name}</span>
              </li>
            ) : null}
            {item?.issueDate ? (
              <li>
                <span>Issue Date</span>
                <span>{renderDate(item?.issueDate)}</span>
              </li>
            ) : null}
            {item?.expiryDate ? (
              <li>
                <span>Expiry Date</span>
                <span>{renderDate(item?.expiryDate)}</span>
              </li>
            ) : null}
            {renderImage && renderImage(item)}
          </>
        );
      }}
    />
  );
};

export const getApprovalTrackingStatus = (status, isAuthorizer) => {
  let array = [];
  if (status === ACTION?.PENDING) {
    array = [APPROVAL_MESSAGES_ARRAY[2]?._id]
    if (isAuthorizer) {
      array.push(APPROVAL_MESSAGES_ARRAY[8]?._id);
    }
  } else if (status === ACTION?.APPROVED) {
    array = [APPROVAL_MESSAGES_ARRAY[3]?._id]
  } else if (status === ACTION?.REJECTED) {
    array = [APPROVAL_MESSAGES_ARRAY[4]?._id, APPROVAL_MESSAGES_ARRAY[5]?._id]
  }
  return array
};

export const getPhoneNumber = (phone = '', iso = '') => {
  if (phone) {
    let code = countryData?.find((country) => country?.iso === iso)?.code?.replace('+', '')
    let response = phone
    if (phone.startsWith(code)) {
      response = phone.replace(code, '');
    }
    return response;
  }
  return '';
}

export const pad_with_zeroes = (number, length) => {

  var my_string = '' + number;
  while (my_string.length < length) {
    my_string = '0' + my_string;
  }

  return my_string;

};

export const RenderCardWithLoading = ({ title, value, className = '', loading, skeletonClass = '' }) => {
  return loading ? <Skeleton.Button active={true} shape={'square'} className={skeletonClass} /> : <Card bordered={false} className={`card card-small ${className}`} >
    <Statistic
      title={title}
      value={value}
    />
  </Card>
};

export const base64toFile = (base64String, fileName, mimeType) => {
  const byteCharacters = atob(base64String.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
  const arrayBuffer = new ArrayBuffer(byteCharacters.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteCharacters.length; i++) {
    uint8Array[i] = byteCharacters.charCodeAt(i);
  }
  const blob = new Blob([uint8Array], { type: mimeType });
  const file = new File([blob], fileName, { type: mimeType });

  return file;
}

export function resizeImage(base64Str) {

  var img = new Image();
  img.src = base64Str;
  var canvas = document.createElement('canvas');
  var MAX_WIDTH = 624;
  var MAX_HEIGHT = 351;
  var width = img.width;
  var height = img.height;

  if (width > height) {
    if (width > MAX_WIDTH) {
      height *= MAX_WIDTH / width;
      width = MAX_WIDTH;
    }
  } else {
    if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height;
      height = MAX_HEIGHT;
    }
  }
  canvas.width = width;
  canvas.height = height;
  var ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0, width, height);
  return { url: canvas.toDataURL(), width, height };
}

export const ConditionalRendering = ({ condition, children, elseChildren }) => {
  if (condition) {
    return children;
  } else if (elseChildren) {
    return elseChildren;
  }
  return null;
}

export const getCardStatus = (val) => {
  if (val === '24') {
    return 'Active';
  }
  return 'Not Active';
}

export const getDotNetCardStatusDescription = (val) => {
  return DOTNET_CARD_STATUS.find((status) => status?.id === val) || {};
}

export const startOfDay = (date) => {
  return dayjs(date).startOf("day");
};
export const endOfDay = (date) => {
  return dayjs(date).endOf("day");
};
export const dayWithHoursAndMinutes = (date) => {
  const parsedDate = dayjs(date); // Parse the date using dayjs
  return parsedDate.hour(parsedDate.hour()) // Keep the hours unchanged
      .minute(parsedDate.minute())           // Keep the minutes unchanged
      .second(0)                             // Set seconds to 0
      .millisecond(0);
};

const renderFilterData = (array, key = '', displayKey = '', type = RADIO) => {
  if (array && array?.length) {
    return array.map((item) =>
      type === RADIO ? (
        <Radio value={item[key]}>
          {readableText(item[displayKey])}
        </Radio>
      ) : (
        <Checkbox value={item[key]}>{readableText(item[displayKey])}</Checkbox>
      )
    );
  }
};

export const clearFilter = (dataIndex, allFilter, setAllFilter) => {
  let filters = allFilter;
  delete filters[dataIndex];
  setAllFilter({ ...filters, page: 1 });
};

export const renderTextBasedFilter = (placeholder, dataIndex, allFilter, setAllFilter) => {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div className="textORDate-filter-modal" key={dataIndex}>
          <CField
            name={dataIndex}
            placeholder={placeholder}
            placeholderHide={!placeholder?.length}
            value={selectedKeys?.value || allFilter[dataIndex]}
            onChange={(e) => setSelectedKeys({ value: e.target.value })}
          />
          <div
            className="d-flex flex-row"
            style={{ justifyContent: "space-between" }}
          >
            <CButton
              title="Clear"
              onClick={() => {
                clearFilters();
                confirm();
                clearFilter(dataIndex, allFilter, setAllFilter);
                // setAllFilter({ ...allFilter, [dataIndex]: null, page: allFilter?.page });
              }}
              size="middle"
              buttonLayout="invisible-button"
              disabled={!(allFilter?.[dataIndex])}
            />
            <CButton
              title="Search"
              fieldType='submit'
              type="primary"
              disabled={!(selectedKeys?.value || allFilter?.[dataIndex])}
              onClick={() => {
                clearFilters();
                confirm();
                setAllFilter({ ...allFilter, [dataIndex]: selectedKeys?.value || allFilter?.[dataIndex], page: 1 });
              }}
              size="middle"
            />
          </div>
        </div>
      );
    },
    filterIcon: (filtered) => <BiSearch className={allFilter?.[dataIndex]?.length ? "filterActive" : ""} size={16} />,
  };
};

export const renderSelectFilter = (placeholder, dataIndex, allFilter, setAllFilter, data, key, displayKey, renderCustomOptions) => {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div className="textORDate-filter-modal" key={dataIndex}>
          <CField
            name={dataIndex}
            placeholder={placeholder}
            placeholderHide={!placeholder?.length}
            value={selectedKeys?.value || allFilter[dataIndex]}
            fieldType='select'
            data={data}
            renderSelectedKey={displayKey}
            valueKey={key}
            getObj={false}
            onChange={(value) => setSelectedKeys({ value })}
            {...(renderCustomOptions && { customSelectOption: (data) => renderCustomOptions(data) })}
          />
          <div
            className="d-flex flex-row"
            style={{ justifyContent: "space-between" }}
          >
            <CButton
              title="Clear"
              onClick={() => {
                clearFilters();
                confirm();
                clearFilter(dataIndex, allFilter, setAllFilter);
              }}
              size="middle"
              buttonLayout="invisible-button"
              disabled={!(allFilter?.[dataIndex])}
            />
            <CButton
              title="Search"
              fieldType='submit'
              type="primary"
              disabled={!selectedKeys?.value}
              onClick={() => {
                clearFilters();
                confirm();
                setAllFilter({ ...allFilter, [dataIndex]: selectedKeys?.value, page: 1 });
              }}
              size="middle"
            />
          </div>
        </div>
      );
    },
    filterIcon: (filtered) => <BiSearch className={allFilter?.[dataIndex] ? "filterActive" : ""} size={16} />,
  };
};

export const renderRadioFilter = (dataIndex, allFilter, setAllFilter, data, key, displayKey) => {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div className="list-filter-modal" key={dataIndex}>
          <Radio.Group
            name={dataIndex}
            value={selectedKeys?.value || allFilter[dataIndex]}
            onChange={(e) => setSelectedKeys({ value: e.target.value })}
          >
            {renderFilterData(data, key, displayKey, RADIO)}
          </Radio.Group>
          <div
            className="d-flex flex-row"
            style={{ justifyContent: "space-between" }}
          >
            <CButton
              title="Clear"
              onClick={() => {
                clearFilters();
                confirm();
                clearFilter(dataIndex, allFilter, setAllFilter);
                // setAllFilter({ ...allFilter, [dataIndex]: null, page: allFilter?.page });
              }}
              size="middle"
              buttonLayout="invisible-button"
              disabled={!(allFilter?.[dataIndex])}
            />
            <CButton
              title="Search"
              fieldType='submit'
              type="primary"
              onClick={() => {
                clearFilters();
                confirm();
                setAllFilter({ ...allFilter, [dataIndex]: selectedKeys?.value, page: 1 });
              }}
              disabled={!selectedKeys?.value}
              size="middle"
            />
          </div>
        </div>
      );
    },
    filterIcon: (filtered) => <AiFillFilter className={allFilter?.[dataIndex] ? "filterActive" : ""} size={14} />,
  };
};

export const renderCheckboxFilter = (dataIndex, allFilter, setAllFilter, data, key, displayKey) => {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div className="list-filter-modal" key={dataIndex}>
          <Checkbox.Group
            name={dataIndex}
            value={selectedKeys?.length ? selectedKeys : allFilter[dataIndex]}
            onChange={(val) => setSelectedKeys(val)}
          >
            {renderFilterData(data, key, displayKey, CHECKBOX)}
          </Checkbox.Group>
          <div
            className="d-flex flex-row"
            style={{ justifyContent: "space-between" }}
          >
            <CButton
              title="Clear"
              onClick={() => {
                clearFilters();
                confirm();
                clearFilter(dataIndex, allFilter, setAllFilter);
                // setAllFilter({ ...allFilter, [dataIndex]: null, page: allFilter?.page });
              }}
              size="middle"
              buttonLayout="invisible-button"
              disabled={!(selectedKeys?.length || allFilter?.[dataIndex]?.length)}
            />
            <CButton
              title="OK"
              fieldType='submit'
              type="primary"
              disabled={!(selectedKeys?.length || allFilter?.[dataIndex]?.length)}
              onClick={() => {
                clearFilters();
                confirm();
                setAllFilter({ ...allFilter, [dataIndex]: selectedKeys, page: 1 });
              }}
              size="middle"
            />
          </div>
        </div>
      );
    },
    filterIcon: (filtered) => <AiFillFilter className={allFilter?.[dataIndex]?.length ? "filterActive" : ""} size={14} />,
  };
};

export const renderDateFilter = (placeholder, dataIndex, allFilter, setAllFilter, fieldType = 'date') => {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div className="textORDate-filter-modal" key={dataIndex}>
          <CField
            name={dataIndex}
            placeholder={placeholder}
            showTime={fieldType === "dateAndTime"}
            fieldType="date"
            disableDate={(current) => current > dayjs()}
            value={selectedKeys?.value || dayjs(allFilter[dataIndex])}
            allowClear={false}
            format={fieldType === "dateAndTime" ? "DD-MMM-YYYY hh:mm a" : "DD-MMM-YYYY"}
            onChange={(value) => setSelectedKeys({ value: fieldType === "dateAndTime" ? dayWithHoursAndMinutes(value) : endOfDay(value) })}
          />
          <div
            className="d-flex flex-row"
            style={{ justifyContent: "space-between" }}
          >
            <CButton
              title="Clear"
              onClick={() => {
                clearFilters();
                confirm();
                clearFilter(dataIndex, allFilter, setAllFilter);
              }}
              size="middle"
              buttonLayout="invisible-button"
              disabled={!(selectedKeys?.value || allFilter[dataIndex])}
            />
            <CButton
              title="OK"
              fieldType='submit'
              type="primary"
              disabled={!(selectedKeys?.value || allFilter[dataIndex])}
              onClick={() => {
                clearFilters();
                confirm();
                setAllFilter({ ...allFilter, [dataIndex]: selectedKeys?.value, page: 1 });
              }}
              size="middle"
            />
          </div>
        </div>
      );
    },
    filterIcon: (filtered) => <CgCalendarDates className={allFilter[dataIndex] ? "filterActive" : ""} size={16} />,
  };
};

export const renderRangeFilter = (placeholder, dataIndex0, dataIndex1, allFilter, setAllFilter) => {
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div className="textORDate-filter-modal" key={dataIndex0}>
          <CField
            fieldType="range"
            placeholder={placeholder}
            disableDate={(current) => current > dayjs()}
            value={selectedKeys?.length ? selectedKeys : [dayjs(allFilter[dataIndex0]), dayjs(allFilter[dataIndex1])]}
            allowClear={false}
            format={"DD-MMM-YYYY"}
            onChange={(val) => setSelectedKeys([startOfDay(val[0]), endOfDay(val[1])])}
          />
          <div className="d-flex flex-row" style={{ justifyContent: "space-between" }} >
            <CButton
              title="Clear"
              onClick={() => {
                clearFilters();
                confirm();
                clearFilter(dataIndex0, allFilter, setAllFilter);
                clearFilter(dataIndex1, allFilter, setAllFilter);
              }}
              size="middle"
              buttonLayout="invisible-button"
              disabled={selectedKeys?.length || !(allFilter?.[dataIndex0] && Object.keys(allFilter?.[dataIndex0])?.length)}
            />
            <CButton
              title="OK"
              fieldType='submit'
              type="primary"
              onClick={() => {
                clearFilters();
                confirm();
                setAllFilter({ ...allFilter, [dataIndex0]: selectedKeys[0], [dataIndex1]: selectedKeys[1], page: 1 });
              }}
              disabled={!selectedKeys?.length && !(allFilter?.[dataIndex0] && Object.keys(allFilter?.[dataIndex0])?.length)}
              size="middle"
            />
          </div>
        </div>
      );
    },
    filterIcon: () => <BsCalendar4Range className={!(allFilter[dataIndex0] || allFilter[dataIndex1]) ? "" : "filterActive"} size={16} />,
  };
};

export const filterKeys = {
  employeeWalletId: 'Wallet Id',
  employeeName: 'Employee Name',
  from: 'Created Date',
  to: 'Created Date',

};

export const getFiltersForTags = (allFilter, exceptionalConditions = []) => {
  return Object.entries(allFilter).reduce((acc, [key, value]) => {
    let condition = true;
    if (exceptionalConditions?.length) {
      condition = !exceptionalConditions?.some(cond => cond === key);
    }
    if (key !== 'page' && key !== 'limit' && condition) {
      const filterName = filterKeys[key] || key;
      if (key.includes('from') || key.includes('to')) {
        if (!acc.filter(f => f?.label === filterName)?.length) {
          acc.push({
            label: filterName,
            value: [key],
          });
        } else {
          let previous = acc.find(f => f?.label === filterName);
          previous.value.push(key);
          acc = acc.filter(f => f?.label !== filterName);
          acc.push(previous);
        }
      } else {
        acc.push({
          label: filterName,
          value: key,
        });
      }
    }
    return acc;
  }, []);
};

export const convertListings = (val = '', withD = false, reverse = false) => {
  const subscribeText = withD ? 'Subscribed' : 'Subscribe';
  const unsubscribeText = withD ? 'Unsubscribed' : 'Unsubscribe';

  switch (val) {
    case WHITELIST_BLACKLIST.WHITE_LIST:
      return reverse ? unsubscribeText : subscribeText;
    case WHITELIST_BLACKLIST.BLACK_LIST:
      return reverse ? subscribeText : unsubscribeText;
    default:
      return '';
  }
};

export const getEIDFromDocs = (docs = []) => {
  let filteredDocs = [];
  if (docs && docs?.length) {
    filteredDocs = docs.filter(doc => doc.documentId === '6').pop();
    return filteredDocs
  }
}

export const renderEID = (val = '', renderButton = false) => {
  return renderButton ?
    <CButton leftIcon={<AiOutlineEye className="c-icon large" />}
      onClick={() => openFileInBrowser(getEIDFromDocs(val)?.url, true)} containerClass='margin-bottom_zero margin-right_zero' buttonLayout='primary-outline' title="View" />
    :
    <AiOutlineEye
      className="c-icon large"
      onClick={() => openFileInBrowser(getEIDFromDocs(val)?.url, true)}
    />
}

export const openNotification = (message = '', description = '', placement = 'topRight') => {
  notification.info({
    message,
    description,
    placement,
  });
};

export const renderDOB = (DOB) => {
  if (new Date(dayjs(DOB, 'YYDDMM')) > new Date()) {
    return dayjs(DOB, 'YYDDMM').subtract(100, 'years').format('DD-MM-YYYY')
  } else {
    return dayjs(DOB, 'YYDDMM').format('DD-MM-YYYY')
  }
};

export const phoneUtil = PhoneNumberUtil.getInstance();

export const phoneValidateNumberRegex = async (country, string, { createError, path }, title = 'Mobile number') => {
  try {
    if (string) {

      let code = country?.code?.replace('+', '');
      if (country?.code && string?.startsWith(code)) {
        return createError({
          path,
          message: `Please enter valid ${title?.toLowerCase()}.`,
        });
      }

      if ((/^[0-9]*$/).test(string)) {
        const number = phoneUtil.parse(string, (country?.cca2 || country?.iso));

        let valid = await phoneUtil.isValidNumber(number);

        if (!valid) {
          return createError({
            path,
            message: `Please enter valid ${title?.toLowerCase()}.`,
          });
        } else {
          return true
        }
      } else {
        return createError({
          path,
          message: `Please enter valid ${title?.toLowerCase()}.`,
        });
      }


    } else {
      return createError({
        path,
        message: `${title} is required.`,
      });
    }
  } catch (e) {
    return createError({
      path,
      message: `Please enter valid ${title?.toLowerCase()}.`,
    });
  }

};

export const getCountryObj = (code) => {
  return countryData?.find(country => (country?.code === code || country?.iso === code));
}

export const getPhoneNumberWithCode = (phone = '', code = '') => {
  if (phone.startsWith(code)) {
    return phone;
  } else {
    return `${code}${phone}`;
  }
}
export const getPhoneNumberWithOutCode = (phone = '', code = '') => {
  if (phone.startsWith(code)) {
    return phone.replace(code, '');
  } else {
    return phone;
  }
}

export const convertDateIntoString = (date) => {
  return dayjs(date).format('YYYY-MMM-DD');
}

export const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secondsRemaining = seconds % 60;
  return `${minutes}:${secondsRemaining.toString().padStart(2, '0')}`;
};

export const checkTokenExpiration = async () => {

  try {
    const token = await getValueIntoLocalStorage(TOKEN);
    if (!token) {
      throw new Error("Token not found in local storage");
    }

    const decodedToken = jwtDecode(token);
    const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds

    const currentTime = Date.now();
    const timeRemaining = (expirationTime - currentTime) - 10000;

    const twoMinutesInMillis = 2.12 * 60 * 1000; // approx 2 minutes in milliseconds 127 seconds

    return {
      timeRemaining,
      twoMinutesInMillis,
    };
  } catch (error) {
    return {
      timeRemaining: 0,
      twoMinutesInMillis: 0,
    };
  }

};

export const maskEmail = (email) => {
  let [local, domain] = email.split("@");
  let visibleCharacters = 3;

  if (local.length > visibleCharacters * 2) {
    let maskedPart = "*".repeat(local.length - visibleCharacters * 2);
    local = local.substring(0, visibleCharacters) + maskedPart + local.substring(local.length - visibleCharacters);
  } else if (local.length > 2) {
    local = local[0] + "*".repeat(local.length - 2) + local[local.length - 1];
  }

  return `${local}@${domain}`;
};

export const maskPhoneNumber = (phoneNumber = '', code = '971') => {
  let phoneWithoutCode = getPhoneNumberWithOutCode(phoneNumber, code);
  phoneWithoutCode = phoneWithoutCode.substring(0, 3) + '***' + phoneWithoutCode.substring(phoneWithoutCode.length - 3);
  return code + phoneWithoutCode;
};

export const getStringFromBool = (value) => {
  if (value === 'Yes' || value === true) return 'Yes';
  return 'No';
}

export const restrictDateOfBirth = (current) => {
  return current >= dayjs().startOf("year").subtract(18, 'years')
}

export const renderTopic = (topic = '', obj = {}) => {

  let modifiedTopic = obj?.childCompany && Object.keys(obj?.childCompany)?.length ? "Child Company on board request" : _readableTextCap(topic) || '';
  if (topic === TOPIC.SALARY_FILE_UPLOAD) {
    modifiedTopic = modifiedTopic.concat(` (${dayjs(obj?.salaryFileDate).format("MMM YYYY")})`)
  } else if (topic === TOPIC.DEPOSIT_FILE_UPLOAD) {
    modifiedTopic = modifiedTopic.concat(` (${formatter(obj?.depositSlipAmount)})`)
  } else if (topic === TOPIC?.CENTIV_LOAD_FUNDS) {
    modifiedTopic = modifiedTopic.concat(` (${formatter(obj?.loadFundCentivAmount || 0)})`)
  } else if (topic === TOPIC?.CHANGE_EMPLOYEE_TYPE) {
    modifiedTopic = 'Add Other Bank Details'
  } else if (topic === TOPIC?.CHANGE_EMPLOYEE_TYPE_TO_KAMELPAY) {
    modifiedTopic = 'Add PayD Details'
  }
  return modifiedTopic;

};
