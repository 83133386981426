const EMPLOYER = {

    GET_EMPLOYER: 'GET_EMPLOYER',
    ADD_EMPLOYER: 'ADD_EMPLOYER',
    GET_ALL_EMPLOYER: 'GET_ALL_EMPLOYER',

    SYNC_EMPLOYEERS: 'SYNC_EMPLOYEERS',

    TOGGLE_CLIENT_ACL: 'TOGGLE_CLIENT_ACL',

    GET_EMPLOYER_DASHOARD: 'GET_EMPLOYER_DASHOARD',

    ADD_ESHTABLISHMENTS: "ADD_ESHTABLISHMENTS",

    CHANGE_PASSWORD: "CHANGE_PASSWORD",

    UPLOAD_EOS: "UPLOAD_EOS",

    UPDATE_CLIENT_DETAILS: "UPDATE_CLIENT_DETAILS",

    GET_EDIT_CONSTANTS: "GET_EDIT_CONSTANTS",
    ADD_UPDATE_ONBOARDING_SOURCE: "ADD_UPDATE_ONBOARDING_SOURCE",
    ADD_UPDATE_PRODUCT: "ADD_UPDATE_PRODUCT",
    ADD_UPDATE_CHARGES: "ADD_UPDATE_CHARGES",
    ADD_UPDATE_BUSINESS_OWNER: "ADD_UPDATE_BUSINESS_OWNER",
    ADD_UPDATE_ADDITIONAL_DETAILS: "ADD_UPDATE_ADDITIONAL_DETAILS",
    ADD_UPDATE_CASHBACK: "ADD_UPDATE_CASHBACK",
    ADD_UPDATE_OPERATION_USER: "ADD_UPDATE_OPERATION_USER",

    GET_SINGLE_EMPLOYER: 'GET_SINGLE_EMPLOYER',


};

export default EMPLOYER;
