const MobileAppApprovals = {

    GET_APP_APPROVALS: 'GET_APP_APPROVALS',

    APP_APPROVE_REJECT: 'APP_APPROVE_REJECT',

    GET_SINGLE_APP_APPROVAL: 'GET_SINGLE_APP_APPROVAL',

    GET_APPROVALS_APPLICABLES : 'GET_APPROVALS_APPLICABLE',

    LOAD_MORE_APPROVALS_APPLICABLES : 'LOAD_MORE_APPROVALS_APPLICABLE',

};
export default MobileAppApprovals