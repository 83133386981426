import HelloPaisaFeeConstant from "../constants/HelloPaisaFee.constant";

const initialState = {
    helloPaisaFeeList: [],
    getHelloPaisaFeeListLoading: false,
    helloPaisaFeeListMetaData: {},

    addEditFeeLoading: false,

    getCountries: [],
    getCountriesLoading: false
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case HelloPaisaFeeConstant.GET_HELLO_PAISA_FEE_LIST:
            return {
                ...state,
                helloPaisaFeeList: action.data,
                getHelloPaisaFeeListLoading: action.loading,
                helloPaisaFeeListMetaData: action.metaData
            };
        case HelloPaisaFeeConstant.ADD_EDIT_HELLO_PAISA_FEE_LIST:
            return {
                ...state,
                addEditFeeLoading: action.loading,
            };
        case HelloPaisaFeeConstant.GET_COUNTRY_WITH_COUNTRY_CODE:
            return {
                ...state,
                getCountries: action.data,
                getCountriesLoading: action.loading,
            };


        default:
            return state;
    }
};