const OFFERS = {
    
    CREATE_OFFER : "CREATE_OFFER",

    GET_OFFERS: "GET_OFFERS",

    GET_USERS : "GET_USERS",
    
    GET_OFFERS_APPLICABLES : 'GET_OFFERS_APPLICABLES',

    LOAD_MORE_APPLICABLES : 'LOAD_MORE_APPLICABLES'
    
};

export default OFFERS;