const FindCardHolderConstant = {

    GET_EMPLOYEE: 'GET_EMPLOYEE',

    GET_BENEFICIARIES: 'GET_BENEFICIARIES',

    GET_BENEFICIARY_COUNTRIES: 'GET_BENEFICIARY_COUNTRIES',
    LOAD_MORE_BENEFICIARY_COUNTRIES: 'LOAD_MORE_BENEFICIARY_COUNTRIES',

    GET_TYPES_AND_BANKS: 'GET_TYPES_AND_BANKS',

    GET_BANKS: 'GET_BANKS',
    LOAD_MORE_BANKS: 'LOAD_MORE_BANKS',

    GET_BRANCHES: 'GET_BRANCHES',
    LOAD_MORE_BRANCHES: 'LOAD_MORE_BRANCHES',

    GET_BANK_BY_IBAN_IFSC: 'GET_BANK_BY_IBAN_IFSC',

    ADD_BENEFICIARY: 'ADD_BENEFICIARY',

    DELETE_BENEFICIARY: 'DELETE_BENEFICIARY',

    EDIT_BENEFICIARY: 'EDIT_BENEFICIARY',

    GET_OTP_BENEFICIARY: 'GET_OTP_BENEFICIARY',

    RESEND_OTP_BENEFICIARY: 'RESEND_OTP_BENEFICIARY',

    SEARCH_OLD_MOBILE_APP_USER: 'SEARCH_OLD_MOBILE_APP_USER',
    SEARCH_NEW_MOBILE_APP_USER: 'SEARCH_NEW_MOBILE_APP_USER',
    MERGE_MOBILE_APP_USERS: 'MERGE_MOBILE_APP_USERS',

    GET_REFERENCE_INFO: "GET_REFERENCE_INFO",
    LOAD_MORE_REFERENCE_INFO: 'LOAD_MORE_REFERENCE_INFO',

    GET_CLIENTS: 'GET_CLIENTS'
};

export default FindCardHolderConstant;
