import RemittanceRateConstant from "../constants/RemittanceRate.constant";

const initialState = {
    remittanceRateList: [],
    getRemittanceRateListLoading: false,
    remittanceRateListMetaData: {},

    addEditRemittanceRateLoading: false,

    getCountries: [],
    getCountriesLoading: false
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case RemittanceRateConstant.GET_REMITTANCE_RATE_LIST:
            return {
                ...state,
                remittanceRateList: action.data,
                getRemittanceRateListLoading: action.loading,
                remittanceRateListMetaData: action.metaData
            };
        case RemittanceRateConstant.ADD_EDIT_REMITTANCE_RATE_LIST:
            return {
                ...state,
                addEditRemittanceRateLoading: action.loading,
            };
        case RemittanceRateConstant.GET_COUNTRY_WITH_COUNTRY_CODE:
            return {
                ...state,
                getCountries: action.data,
                getCountriesLoading: action.loading,
            };


        default:
            return state;
    }
};
