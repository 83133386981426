import BENEFICIARY_SCREENING from "../constants/BeneficiaryScreening.constant";

const initialState = {
    getBeneficiaryScreeningLoading: false,
    beneficiaryScreening: [],
    beneficiaryScreeningMetaData: {},
    retryBeneficiaryScreeningLoading: false,
    checkDuplicatesLoading: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case BENEFICIARY_SCREENING.GET_BENEFICIARY_SCREENING:
            return {
                ...state,
                getBeneficiaryScreeningLoading: action.loading,
                beneficiaryScreening: action.data,
                beneficiaryScreeningMetaData: action.metaData
            };
        case BENEFICIARY_SCREENING.RETRY_BENEFICIARY_SCREENING:
            return {
                ...state,
                retryBeneficiaryScreeningLoading: action.loading,
            };

        case BENEFICIARY_SCREENING.CHECK_DUPLICATES:
            return {
                ...state,
                checkDuplicatesLoading: action.loading,
            };

        default:
            return state;
    }
};
