import { getPromosApplicable } from "../actions/Promos.action";
import PROMOS from "../constants/Promos.constant";

const initialState = {
    promos: [],
    getLoading: false,
    metaData: {},

    companies: [],
    getCompaniesLoading: false,

    users: [],
    getUsersLoading: false,

    billers: [],
    getBillersLoading: false,

    getCreatePromoLoading: false,

    getPromosApplicables : [],
    getPromosApplicablesLoading : false,
    applicablesMetaData : [],
    loadMoreLoading : false,
    canLoadMore : true,

};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case PROMOS.GET_PROMOS:
            return {
                ...state,
                promos: action.data,
                getLoading: action.loading,
                metaData: action.metaData,
            };
        case PROMOS.GET_COMPANIES:
            return {
                ...state,
                companies: action.data,
                getCompaniesLoading: action.loading,
            };
        case PROMOS.GET_USERS:
            return {
                ...state,
                users: action.data,
                getUsersLoading: action.loading,
            };
        case PROMOS.GET_BILLERS:
            return {
                ...state,
                billers: action.data,
                getBillersLoading: action.loading,
            };
        case PROMOS.CREATE_PROMO:
            return {
                ...state,
                getCreatePromoLoading : action.loading
            }
        case PROMOS.GET_PROMOS_APPLICABLES:
            return {
                ...state,
                getPromosApplicables: action.data,
                getPromosApplicablesLoading: action.loading,
                canLoadMore : true,
                loadMoreLoading : false,
                applicablesMetaData : action.metaData,
            }
            case PROMOS.LOAD_MORE_APPLICABLES:
                return {
                    ...state,
                    getPromosApplicables: [...state.getPromosApplicables, ...action.data], 
                    canLoadMore: action?.canLoadMore, 
                    loadMoreLoading: action.loading, 
                    applicablesMetaData : action.metaData
                  };
        default:
            return state;
    }
};
