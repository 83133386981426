import * as Yup from "yup";

const scheme = Yup.object().shape({
  reason: Yup.string()
    .label("Reason")
    .required("Please enter reason")
    .min(4, "Reason must be atleast 4 characters")
    .max(250, "Reason should not exceed 250 characters"),
  attachmentFile: Yup.mixed()
    .label("File")
    .default({}),
});

export default scheme;