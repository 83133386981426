import React, { useEffect, useState } from "react";
import { memo } from "react";
import { ConditionalRendering, formatter, getQueryFiltersAsObject, pushFiltersToQueryString, renderDate } from "../../../utils/methods";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Table, Tag } from "antd";
import { PageWrapper } from "../../../containers";
import { getEmpAdvanceSalary } from "../../../store/actions/AdvanceSalary.action";
import CApplyAdvanceSalary from "../cApplyAdvanceSalary";

function AdvanceSalaryEmp() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const employee = location.state?.employee;
  const client = location.state?.client;
  const mobileUser = location.state?.mobileUser;
  const canApply = location.state?.canApply;

  const [limit, setLimit] = useState(10);
  const [allFilter, setAllFilter] = useState({});

  const reduxState = useSelector(({ advanceSalary }) => {
    return {
      data: advanceSalary?.empAdvanceSalary,
      metaData: advanceSalary?.metaData,
      loading: advanceSalary?.loading,
    };
  });

  useEffect(() => {
    // get filters from query string and reflect on site
    let filters = getQueryFiltersAsObject(location);
    setAllFilter({
      ...filters,
      page: filters?.page ? filters?.page : 1,
    });
  }, []);

  useEffect(() => {
    pushFilter(1);
  }, [allFilter, reduxState?.selectedClient]);

  const pushFilter = async (val) => {
    let payload = {
      page: val || allFilter?.page || 1,
    };
    await pushFiltersToQueryString(payload, navigate, location);
    get();
  };

  const get = () => {
    let payload = {
      ...allFilter,
      page: allFilter?.page || 1,
      walletId: employee.kamelpayWalletId,
      client: client._id,
      limit: limit
    };
    dispatch(getEmpAdvanceSalary(payload));
  };

  const headerProps = {
    title: `${employee.displayName}'s Advance Salary`,
    subTitle: "",
    // canRenderCompany: true,
    // companyLoading: reduxState?.loading,
    renderRight: () => renderRight(),
    canGoBack: true,
  };
  const renderRight = () => {
    return <ConditionalRendering
      condition={canApply}
      children={
        <CApplyAdvanceSalary
          employee={employee}
          client={client}
          mobileUser={mobileUser}
          noGradient
        />}
    />
  };

  const renderTag = (val) => {
    return <Tag color={val === 'Pending' ? 'orange' : val === 'Reject' ? 'red' : val === 'Disbursed' ? 'green' : ''}>{val}</Tag>
  }

  const COLUMNS = [
    {
      title: "Id",
      dataIndex: "advanceSalaryId",
      render: (val) => (
        <div className="bold-text break ">{val}</div>
      ),
      width: 50
    },
    {
      title: "Employee Name",
      dataIndex: "empName",
      render: (val) => <div className="bold-text ">{val}</div>,
      width: 100,
    },
    {
      title: "Last Salary",
      dataIndex: "lastSalary",
      render: (val) => <div className="bold-text ">{formatter(val)}</div>,
      width: 100,
    },
    {
      title: "Request Amount",
      dataIndex: "requestAmount",
      render: (val) => <div className="bold-text ">{formatter(val)}</div>,
      width: 100,
    },
    {
      title: "Status",
      dataIndex: "advanceSalaryStatus",
      render: renderTag,
      width: 100,
    },
    {
      title: "Requested Date",
      dataIndex: "requestedDate",
      render: (val) => <div className="bold-text">{val ? renderDate(val) : '-'}</div>,
      width: 100,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      render: (val) => <div className="bold-text">{val ? renderDate(val) : '-'}</div>,
      width: 100,
    },
    {
      title: "Approval Date",
      dataIndex: "approvalDate",
      render: (val) => <div className="bold-text">{val ? renderDate(val) : '-'}</div>,
      width: 100,
    },
    {
      title: "Date Of Disbursment",
      dataIndex: "dateOfDisbursment",
      render: (val) => <div className="bold-text">{val ? renderDate(val) : '-'}</div>,
      width: 100,
    }
  ];

  const expandedRowRender = (record, index, indent, expanded) => {

    const COLUMNS = [
      {
        title: 'Id',
        dataIndex: 'advanceSalaryDetailId',
        key: 'advanceSalaryDetailId',
        width: 200,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 200,
      },
      {
        title: "Debit",
        dataIndex: "debitAmount",
        render: (val, obj) => (
          <div className="bold-text red-color">
            {obj?.mode === "D" ? formatter(val) : ""}
          </div>
        ),
        width: 200,
      },
      {
        title: "Credit",
        dataIndex: "creditAmount",
        render: (val, obj) => (
          <div className="bold-text green-color">
            {obj?.mode === "C" ? formatter(val) : ""}
          </div>
        ),
        width: 200,
      }
    ];

    return <Table
      className={`${record?.advanceSalaryDetail ? "" : "no-data-table"}`}
      scroll={{ x: '100%' }}
      defaultExpandAllRows={false}
      columns={COLUMNS}
      dataSource={record?.advanceSalaryDetail}
      pagination={false}
    />;
  }

  return (
    <>
      <PageWrapper headerProps={headerProps}
        loadingWithOverlay={true}
        loading={reduxState?.loading}
        emptyText={"No Data"}
      >
        <Table
          className={`c-table expand-child layout-auto ${reduxState?.data?.length ? "" : "no-data-table"}`}
          rowKey={(obj) => obj?.advanceSalaryId}
          scroll={{ x: '100%' }}
          defaultExpandAllRows={false}
          loading={reduxState?.loading}
          columns={COLUMNS}
          dataSource={reduxState.data}
          expandable={{
            expandedRowRender,
            defaultExpandAllRows: false,
          }}
          pagination={{
            total: reduxState?.metaData?.totalDocuments,
            pageSize: limit,
            showSizeChanger: false,
            current: reduxState?.metaData?.page,
            defaultCurrent: allFilter?.page,
            onChange: (page) => setAllFilter({ ...allFilter, page })
          }}
        />
      </PageWrapper>
    </>
  );
}

export default memo(AdvanceSalaryEmp);
