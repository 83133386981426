import React, { useEffect, useState, useCallback, useMemo, Suspense } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import {
  Login,
  Otp,
  Dashboard,
  Approval,
  Salaries,
  AddEmployee,
  AddEmployer,
  Employer,
  Admin,
  Employee,
  ManageAccess,
  CentivCard,
  CentivCardDetails,
  EmployeeDetailsUpdated,
  FullView,
  AllTransactions,
  Transactions,
  ExchangeHouse,
  Branches,
  ExchangeHouseApproval,
  OnboardApprovals,
  Profile,
  AdvanceSalary,
  SalaryStatus,
  Marketplace,
  MarketplaceCategories,
  MarketplaceSuppliers,
  MarketplaceAddProduct,
  MarketplaceProductView,
  MarketplaceOrders,
  FindCardHolder,
  Remittances,
  Logs,
  ReplacementCards,
  AuthTwoFactor,
  EmployerTeamMembers,
  ManageAccessTeamMember,
  TransactionsHistory,
  ScratchedCards,
  LoginHistory,
  EditEmployer,
  Offers,
  Promos,
  MobileAppApproval,
  TransactionScreening,
  MobilePromotions,
  BeneficiaryScreening,
  DeliveryModule,
  HelloPaisaFee,
  MobileAppLogsModule,
  RemittanceRate
} from "../pages";
import { useDispatch, useSelector } from "react-redux";
import { SideMenu } from "../containers";
import { Navigate } from "react-router";
import { getAllTopics, getConstants, setApprovalsCount, toggleLeftDrawer } from "../store/actions/Common.action";
import {
  AdminIcon,
  ApprovalIcon,
  EmployerIcon,
  transactionIcon,
  HomeIcon,
  requestIcon,
  processSalaries,
} from "../assets/svg";
import { AdvanceSalaryEmp, Beneficiaries, CNPL, CRefreshModal } from "../components";
import { ACL_ACCESS_LIST, ACL_MODULES, IsAllowedOption } from "../utils/acl";
import { CLoading } from "../uiComponents";
import { connectionSocket, socket } from "../utils/socket";
import { useClientPendingApprovalCount } from "../pages/approval/helper";
import { flushSync } from "react-dom";

function Auth() {
  return (
    <Suspense fallback={<CLoading />}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/auth" element={<AuthTwoFactor />} />
      </Routes>
    </Suspense>
  );
}

function Root(props) {

  const canAllRequestView = IsAllowedOption(ACL_MODULES.ALL_REQUESTS, ACL_ACCESS_LIST.VIEW);
  const canAllRequestChecker = IsAllowedOption(ACL_MODULES.ALL_REQUESTS, ACL_ACCESS_LIST.ALL_REQUEST_CHECKER);
  const canAllRequestAuthorizer = IsAllowedOption(ACL_MODULES.ALL_REQUESTS, ACL_ACCESS_LIST.ALL_REQUEST_AUTHORIZER);

  const canExchangeHouseRequestChecker = IsAllowedOption(ACL_MODULES.EXCHANGE_HOUSES, ACL_ACCESS_LIST.REQUEST_CHECKER);
  const canExchangeHouseRequestViewer = IsAllowedOption(ACL_MODULES.EXCHANGE_HOUSES, ACL_ACCESS_LIST.VIEW);
  const canExchangeHouseRequestAuthorizer = IsAllowedOption(ACL_MODULES.EXCHANGE_HOUSES, ACL_ACCESS_LIST.REQUEST_AUTHORIZER);

  const canEmployerRequestChecker = IsAllowedOption(ACL_MODULES.EMPLOYERS, ACL_ACCESS_LIST.REQUEST_CHECKER);
  const canEmployerRequestViewer = IsAllowedOption(ACL_MODULES.EMPLOYERS, ACL_ACCESS_LIST.VIEW);
  const canEmployerRequestAuthorizer = IsAllowedOption(ACL_MODULES.EMPLOYERS, ACL_ACCESS_LIST.REQUEST_AUTHORIZER);

  const canCentivCardRequestChecker = IsAllowedOption(ACL_MODULES.CENTIV_CARD, ACL_ACCESS_LIST.REQUEST_CHECKER);
  const canCentivCardRequestViewer = IsAllowedOption(ACL_MODULES.CENTIV_CARD, ACL_ACCESS_LIST.VIEW);
  const canCentivCardRequestAuthorizer = IsAllowedOption(ACL_MODULES.CENTIV_CARD, ACL_ACCESS_LIST.REQUEST_AUTHORIZER);

  const canProcessSalariesRequestChecker = IsAllowedOption(ACL_MODULES.PROCESS_SALARIES, ACL_ACCESS_LIST.REQUEST_CHECKER);
  const canProcessSalariesRequestViewer = IsAllowedOption(ACL_MODULES.PROCESS_SALARIES, ACL_ACCESS_LIST.VIEW);
  const canProcessSalariesRequestAuthorizer = IsAllowedOption(ACL_MODULES.PROCESS_SALARIES, ACL_ACCESS_LIST.REQUEST_AUTHORIZER);
  const canViewTransactionHistory = IsAllowedOption(ACL_MODULES?.FIND_CARD_HOLDER, ACL_ACCESS_LIST.VIEW_TRANSACTION_HISTORY) || IsAllowedOption(ACL_MODULES?.EMPLOYEE, ACL_ACCESS_LIST.VIEW_TRANSACTION_HISTORY);
  const canViewScratchedCard = IsAllowedOption(ACL_MODULES?.FIND_CARD_HOLDER, ACL_ACCESS_LIST.VIEW_SCRATCHED_CARD) || IsAllowedOption(ACL_MODULES?.EMPLOYEE, ACL_ACCESS_LIST.VIEW_SCRATCHED_CARD);

  const canMobileAppApprovalChecker = IsAllowedOption(ACL_MODULES?.MOBILE_APP_APPROVAL, ACL_ACCESS_LIST.REQUEST_CHECKER);
  const canMobileAppApprovalAuthorizer = IsAllowedOption(ACL_MODULES?.MOBILE_APP_APPROVAL, ACL_ACCESS_LIST.REQUEST_AUTHORIZER);

  const canClientApprovalsAuthorizer = canEmployerRequestAuthorizer || canCentivCardRequestAuthorizer || canProcessSalariesRequestAuthorizer;
  const canClientApprovalsViewer = canEmployerRequestViewer || canCentivCardRequestViewer || canProcessSalariesRequestViewer;
  const canClientApprovalsChecker = canEmployerRequestChecker || canCentivCardRequestChecker || canProcessSalariesRequestChecker;

  const stateProps = useSelector(({ common, auth }) => {
    return {
      user: auth.user,
      isOpenLeftDrawer: common.isOpenLeftDrawer,
      accessRights: auth?.accessRights || [],
      clientApprovalCount: common.clientApproval,
      onBoardApprovalCount: common.onBoardApproval,
      exchangeHouseApprovalCount: common.exchangeHouseApproval,
      mobileAppApprovalCount: common.mobileAppApproval,
    };
  });

  const { user, accessRights, isOpenLeftDrawer, clientApprovalCount, onBoardApprovalCount, exchangeHouseApprovalCount, mobileAppApprovalCount } = stateProps;

  const { preparePayload } = useClientPendingApprovalCount();

  const adminRoutes = useMemo(() => [
    {
      key: ACL_MODULES.DASHBOARD,
      title: "Dashboard",
      icon: HomeIcon,
      path: "/",
      exact: true,
      element: <Dashboard />,
      permissions: [],
    },
    {
      // key: ACL_MODULES.MANAGE_ADMINS,
      key: "ADMIN_VIEW",
      title: "Admins",
      icon: AdminIcon,
      path: "admin",
      exact: true,
      element: <Admin />,
    },

    {
      key: "MANAGE_ACCESS",
      // key: ACL_ACCESS_LIST.MANAGE_ACCESS_ADMIN,
      title: "Manage Access",
      index: false,
      path: "manage-access",
      exact: true,
      hideInMenu: true,
      element: <ManageAccess />,
    },

    ...(canAllRequestView || canClientApprovalsAuthorizer || canClientApprovalsViewer || canClientApprovalsChecker ? [
      {
        // key: ACL_MODULES.CLIENT_APPROVALS,
        key: '',
        title: "Client Approvals",
        icon: ApprovalIcon,
        path: "approval",
        exact: true,
        element: <Approval />,
        permissions: [],
        showTag: true,
        count: clientApprovalCount
      },
    ] : []),

    ...(canAllRequestView || canExchangeHouseRequestViewer || canExchangeHouseRequestChecker || canExchangeHouseRequestAuthorizer ? [
      {
        // key: ACL_MODULES.EXCHANGE_HOUSE_APPROVALS,
        key: '',
        title: "Exchange House Approvals",
        icon: ApprovalIcon,
        path: "exchangeHouseApproval",
        exact: true,
        element: <ExchangeHouseApproval />,
        permissions: [],
        showTag: true,
        count: exchangeHouseApprovalCount
      },
    ] : []),
    ...((canAllRequestView || canEmployerRequestViewer || canExchangeHouseRequestViewer) || (canEmployerRequestChecker || canExchangeHouseRequestChecker) || (canEmployerRequestAuthorizer || canExchangeHouseRequestAuthorizer) ? [{
      // key: ACL_MODULES.ON_BOARD_APPROVALS,
      key: '',
      title: "Onboard Approvals",
      icon: ApprovalIcon,
      path: "onboardApprovals",
      exact: true,
      element: <OnboardApprovals />,
      permissions: [],
      showTag: true,
      count: onBoardApprovalCount
    }] : []),
    ...((canMobileAppApprovalChecker || canMobileAppApprovalAuthorizer || canAllRequestChecker || canAllRequestAuthorizer) ? [{
      key: '',
      title: "Mobile App Approvals",
      icon: ApprovalIcon,
      path: "mobileAppApprovals",
      exact: true,
      element: <MobileAppApproval />,
      permissions: [],
      showTag: true,
      count: mobileAppApprovalCount
    },] : []),
    {
      key: ACL_MODULES.EMPLOYERS,
      title: "Employers",
      icon: EmployerIcon,
      path: "employer",
      exact: true,
      element: <Employer />,
    },
    {
      key: ACL_MODULES.EMPLOYERS,
      title: "Edit Employer",
      icon: EmployerIcon,
      path: "edit-employer",
      hideInMenu: true,
      exact: true,
      element: <EditEmployer />,
    },
    {
      key: ACL_MODULES.EMPLOYER_TEAM_MEMBERS,
      title: "Team Members",
      icon: EmployerIcon,
      path: "employer/team-members",
      exact: true,
      hideInMenu: true,
      element: <EmployerTeamMembers />,
    },
    {
      key: ACL_ACCESS_LIST.EDIT_TEAM_MEMBER,
      title: "Team Members",
      icon: EmployerIcon,
      path: "employer/team-members/manage-access",
      exact: true,
      hideInMenu: true,
      element: <ManageAccessTeamMember />,
    },
    {
      key: ACL_ACCESS_LIST.ADD_EMPLOYER,
      title: "Add Employer",
      path: "employer/add",
      exact: true,
      index: false,
      hideInMenu: true,
      element: <AddEmployer />,
    },
    {
      key: ACL_MODULES.EMPLOYERS,
      title: "Employer",
      path: "employer/view",
      index: false,
      hideInMenu: true,
      element: <FullView />,
    },
    {
      key: ACL_MODULES.EMPLOYEES,
      title: "Employee",
      path: "employer/employees",
      // exact: true,
      index: false,
      hideInMenu: true,
      element: <Employee />,
    },
    {
      key: ACL_ACCESS_LIST.GET_TRANSACTIONS,
      title: "All Transactions",
      // icon: transactionIcon,
      path: "employee/allTransactions",
      exact: true,
      hideInMenu: true,
      element: <AllTransactions />,
    },
    {
      key: ACL_MODULES.EMPLOYEES,
      title: "Add Employee",
      index: false,
      path: "employee/add",
      exact: true,
      element: <AddEmployee />,
      hideInMenu: true,
    },
    {
      key: ACL_MODULES.EMPLOYEE,
      title: "Employee Details",
      index: false,
      path: `employee/view`,
      exact: true,
      element: <EmployeeDetailsUpdated />,
      hideInMenu: true,
    },
    {
      key: ACL_MODULES.EXCHANGE_HOUSES,
      title: "Exchange Houses",
      icon: EmployerIcon,
      path: "exchangeHouses",
      exact: true,
      element: <ExchangeHouse />,
    },
    {
      key: ACL_MODULES.EXCHANGE_HOUSE_BRANCHES,
      title: "Branches",
      path: "exchangeHouses/branches",
      exact: true,
      element: <Branches />,
      hideInMenu: true,
    },
    {
      key: ACL_MODULES.FIND_CARD_HOLDER,
      title: "Find Card Holder",
      icon: requestIcon,
      path: "/card-holders",
      exact: true,
      element: <FindCardHolder />,
    },
    // {
    //   key: "MANAGE_USERS",
    //   title: "Card Activation / PIN Change",
    //   icon: requestIcon,
    //   path: "/manage",
    //   exact: true,
    //   element: <ManageUser />,
    // },
    {
      key: ACL_MODULES.DELIVERY_MODULE,
      title: "Delivery Module",
      icon: requestIcon,
      path: "/delivery",
      exact: true,
      element: <DeliveryModule />,
    },
    {
      key: ACL_MODULES.ADVANCE_SALARY,
      title: "Advance Salary",
      icon: requestIcon,
      path: "/advanceSalary",
      exact: true,
      element: <AdvanceSalary />,
    },
    {
      key: ACL_ACCESS_LIST.VIEW_CNPL,
      title: "CNPL",
      icon: requestIcon,
      path: "/cnpl",
      exact: true,
      element: <CNPL />,
    },
    {
      key: ACL_MODULES.SALARY_STATUS,
      title: "Salary Status",
      icon: processSalaries,
      path: "salaryStatus",
      exact: true,
      element: <SalaryStatus />,
      permissions: [],
    },
    {
      key: ACL_MODULES.TRANSACTIONS,
      title: "Transactions",
      icon: transactionIcon,
      path: "transactions",
      exact: true,
      element: <Transactions />,
    },
    {
      key: ACL_MODULES.REMITTANCES,
      title: "Remittances",
      icon: transactionIcon,
      path: "remittances",
      exact: true,
      permissions: [],
      element: <Remittances />,
    },
    {
      key: ACL_MODULES.CENTIV_CARD,
      title: "Centiv Card",
      path: "centivCard",
      exact: true,
      element: <CentivCard />,
    },
    {
      key: ACL_MODULES.CENTIV_CARD_EMPLOYEE,
      title: "Centiv Card Details Page",
      hideInMenu: true,
      path: "centivCard/details",
      exact: true,
      element: <CentivCardDetails />,
    },
    {
      key: ACL_MODULES.REPLACEMENT_CARDS,
      title: "Replacement Cards",
      path: "replacementCards",
      exact: true,
      element: <ReplacementCards />,
    },
    {
      key: ACL_MODULES.HELLO_PAISA_FEE,
      title: "Hello Paisa Fee",
      path: "helloPaisaFee",
      exact: true,
      element: <HelloPaisaFee />,
    },
    {
      key: ACL_MODULES.REMITTANCE_RATE,
      title: "Remittance Rate",
      path: "/remittance-rate",
      exact: true,
      element: <RemittanceRate />
    },
    ...(canViewScratchedCard ? [{
      key: '',
      title: "Scratched Cards",
      icon: requestIcon,
      hideInMenu: true,
      path: "/scratchedCards",
      exact: true,
      element: <ScratchedCards />,
    }] : []),
    {
      key: ACL_MODULES.SCRATCHED_CARD,
      title: "Scratched Cards",
      icon: requestIcon,
      path: "/scratchedCards",
      exact: true,
      element: <ScratchedCards />,
    },
    {
      key: ACL_MODULES.LOGS,
      title: "Logs",
      path: "logs",
      exact: true,
      element: <Logs />,
    },
    {
      key: ACL_MODULES.MOBILE_APP_LOGS,
      title: "Mobile App Logs",
      path: "/mobile-app-logs",
      exact: true,
      element: <MobileAppLogsModule />
    },
    {
      key: ACL_MODULES.PROMOS,
      title: "Promos",
      path: "promos",
      exact: true,
      element: <Promos />,
    },
    {
      key: ACL_MODULES.OFFERS,
      title: "Offers",
      path: "offers",
      exact: true,
      element: <Offers />,
    },
    {
      key: ACL_MODULES.PROCESS_SALARIES,
      title: "Process Salaries / Fund Transfer Receipt",
      path: "processSalaries",
      exact: true,
      element: <Salaries />,
    },
    ...(canViewTransactionHistory ? [{
      key: '',
      title: "Transactions History",
      icon: requestIcon,
      hideInMenu: true,
      path: "/transactions-history",
      exact: true,
      element: <TransactionsHistory />,
    }] : []),
    {
      key: ACL_MODULES.TRANSACTION_HISTORY,
      title: "Transactions History",
      icon: requestIcon,
      path: "/transactions-history",
      exact: true,
      element: <TransactionsHistory />,
    },
    {
      key: ACL_MODULES.MARKETPLACE,
      title: 'Marketplace',
      path: "/marketplace",
      exact: true,
      element: <Marketplace />,
    },
    {
      key: ACL_MODULES.MARKETPLACE_PRODUCT_CATEGORIES,
      title: "Categories",
      path: "/marketplace/categories",
      exact: true,
      hideInMenu: true,
      element: <MarketplaceCategories />
    },
    {
      key: ACL_MODULES.MARKETPLACE_PRODUCT_SUPPLIERS,
      title: "Suppliers",
      path: "/marketplace/suppliers",
      exact: true,
      hideInMenu: true,
      element: <MarketplaceSuppliers />
    },
    {
      key: ACL_MODULES.MARKETPLACE_PRODUCTS,
      title: "Add Product",
      path: "/marketplace/addProduct",
      exact: true,
      hideInMenu: true,
      element: <MarketplaceAddProduct />
    },
    {
      key: '',
      title: "Login History",
      path: "/login-history",
      exact: true,
      hideInMenu: true,
      element: <LoginHistory />
    },
    {
      key: ACL_MODULES.MARKETPLACE_PRODUCTS,
      title: "Product",
      path: "/marketplace/productView",
      exact: true,
      hideInMenu: true,
      element: <MarketplaceProductView />
    },
    {
      key: ACL_MODULES.MARKETPLACE_PRODUCT_ORDERS,
      title: "Orders",
      path: "/marketplace/orders",
      exact: true,
      hideInMenu: true,
      element: <MarketplaceOrders />
    },
    {
      key: ACL_ACCESS_LIST.VIEW_BENEFICIARIES,
      title: "Beneficiary",
      path: "/beneficiary",
      exact: true,
      hideInMenu: true,
      element: <Beneficiaries />
    },
    {
      key: '',
      title: "Advance Salary emp",
      path: "/advance-salary",
      exact: true,
      hideInMenu: true,
      element: <AdvanceSalaryEmp />
    },
    {
      key: ACL_MODULES.TRANSACTION_SCREENING,
      title: "Transaction Screening",
      path: "/transaction-screening",
      exact: true,
      // hideInMenu: true,
      element: <TransactionScreening />
    },
    {
      key: ACL_MODULES.BENEFICIARY_SCREENING,
      title: "Beneficiary Screening",
      path: "/beneficiary-screening",
      exact: true,
      // hideInMenu: true,
      element: <BeneficiaryScreening />
    },
    // {
    //   key: "",
    //   title: "Mobile Promotions",
    //   path: "/mobile-promotions",
    //   exact: true,
    //   // hideInMenu: true,
    //   element: <MobilePromotions />
    // },
    {
      key: "ALL",
      title: "Profile",
      // icon: profileUser,
      path: "profile",
      exact: true,
      element: <Profile />,
      permissions: [],
    },
    // {
    //   key: "REDIRECT",
    //   path: "*",
    //   element: <Navigate to={"/"} replace />,
    //   hideInMenu: true,
    // },
  ], [user, accessRights, clientApprovalCount, onBoardApprovalCount, exchangeHouseApprovalCount, mobileAppApprovalCount]);

  const [routesState, updateRoutesState] = useState([]);

  const dispatch = useDispatch();
  const history = useNavigate();

  useEffect(() => {
    if (props?.history && props?.history?.location) {
      history(`${props?.history?.location?.pathname}${props?.history?.location?.search}`, { replace: true, state: props?.history?.location?.state });
    }
  }, []);

  const handleEmitApprovalCounts = () => {
    const payload = preparePayload();
    socket.emit("approvalCounts", payload);
  };

  const approvalCountCheck = () => {
    (async () => {
      if (!socket?.connected) {
        await connectionSocket();
      }
      try {
        socket.on(`approvalCounts`, (data) => {
          flushSync(() =>
            dispatch(setApprovalsCount({
              clientApproval: data?.adminClientApprovalCount || 0,
              exchangeHouseApproval: data?.adminExchangeHouseApprovalCount || 0,
              onBoardApproval: data?.adminClientOnBoardApprovalCount || 0,
              mobileAppApproval: data?.adminMobileAppApprovalCount || 0,
            }))
          );
        });
        socket.on(`approval`, () => {
          handleEmitApprovalCounts()
        });
      } catch (err) {
        console.error('connection error', err);
      }
      handleEmitApprovalCounts();
    })();
  }

  const filterRoute = useCallback((array) => array.filter((obj) => {
    if (obj?.children?.length) {
      const filteredChildren = obj?.children.filter((child) => accessRights.find((item) => child?.key === "ALL" ? obj : item?.key?.includes(obj?.key) || item?.access?.includes(obj?.key)));
      if (filteredChildren?.length) {
        obj.children = filteredChildren;
        return obj;
      }
    } else {
      if (accessRights?.length) {
        // || obj?.key === 'MANAGE'
        return accessRights.find((item) => obj?.key === "ALL" ? obj : (item?.key?.includes(obj?.key) && item?.access?.includes(ACL_ACCESS_LIST.VIEW)) || item?.access?.includes(obj?.key))
      } else {
        return obj?.path === 'profile'
      }
    }
  }), [accessRights]);

  useEffect(() => {
    if (user && Object.keys(user)?.length) {
      if (user?.isSuperAdmin) {
        updateRoutesState(adminRoutes);
        dispatch(getAllTopics());
      } else {
        updateRoutesState(filterRoute(adminRoutes));
      }
      dispatch(getConstants());
      approvalCountCheck()
    }
  }, [user, clientApprovalCount, onBoardApprovalCount, exchangeHouseApprovalCount, mobileAppApprovalCount]);

  const toggleLeft = useCallback(() => {
    dispatch(toggleLeftDrawer(!isOpenLeftDrawer));
  }, [dispatch, isOpenLeftDrawer]);

  const toggleClass = useMemo(() => isOpenLeftDrawer ? "left-drawer-open open" : "", [isOpenLeftDrawer]);

  return (
    <div className={`side-menu-with-body ${toggleClass}`}>
      <div className="side-menu-container" onClick={toggleLeft}>
        <SideMenu
          {...props}
          routes={routesState}
          user={user}
          currentPathName={props && props.location ? props.location.pathname : ""}
        />
      </div>
      <div className="body-container scroll-y">
        {routesState?.length ? (
          <Suspense fallback={<CLoading position="relative" />}>
            <Routes>
              {routesState.map((route, index) => (
                <Route key={index} path={route.path} element={route.element} />
              ))}
              {routesState?.length ? (
                <Route
                  path="*"
                  element={<Navigate to={`${routesState[0]?.path}`} replace />}
                />
              ) : null}
            </Routes>
          </Suspense>
        ) : null}
        <CRefreshModal />
      </div>
    </div>
  );
}

export { Auth, Root };
