import FindCardHolderConstant from "../constants/FindCardHolder.constant";

const initialState = {
    data: {},
    getEmployeeDetailLoading: false,

    beneficiaries: [],
    getBeneficiariesLoading: false,
    beneficiariesMetaData: {},

    countries: [],
    specialCountries: [],
    countriesMetaData: {},
    getCountriesLoading: false,
    loadMoreLoading: false,
    canLoadMore: true,
    selectedBeneficiaryCountry: {},

    bankTypes: [],
    specialBanks: [],
    getTypesAndBanksLoading: false,

    banks: [],
    banksMetaData: {},
    getBanksLoading: false,
    banksLoadMoreLoading: false,
    banksCanLoadMore: true,
    selectedBank: {},

    branches: [],
    branchesMetaData: {},
    getBranchesLoading: false,
    BranchesLoadMoreLoading: false,
    BranchesCanLoadMore: true,

    addBeneficiaryLoading: false,

    deleteBeneficiaryLoading: false,

    editBeneficiaryLoading: false,

    getBankByIBANorIFSCLoading: false,

    getOTPBeneficiaryLoading: false,

    resendOTPBeneficiaryLoading: false,

    searchMobileAppUserLoading: false,
    oldMobileAppUser: null,
    newMobileAppUser: null,

    mergeMobileAppUsersLoading: false,

    referenceInfo: [],
    getReferenceInfoloading: false,
    referenceInfoMetaData: {},
    
    clients: [],
    getClientLoading: false,

};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case FindCardHolderConstant.GET_EMPLOYEE:
            return {
                ...state,
                data: action.data,
                getEmployeeDetailLoading: action.loading,
            };

        case FindCardHolderConstant.GET_BENEFICIARIES:
            return {
                ...state,
                beneficiaries: action.data,
                beneficiariesMetaData: action.metaData,
                getBeneficiariesLoading: action.loading,
            };

        case FindCardHolderConstant.GET_BENEFICIARY_COUNTRIES:
            return {
                ...state,
                countries: action.data,
                countriesMetaData: action.metaData,
                getCountriesLoading: action.loading,
                loadMoreLoading: false,
                canLoadMore: true,
                specialCountries: action.specialCountries
            };

        case FindCardHolderConstant.LOAD_MORE_BENEFICIARY_COUNTRIES:
            return {
                ...state,
                countries: [...state.countries, ...action.data],
                countriesMetaData: action.metaData,
                loadMoreLoading: action.loading,
                canLoadMore: action.canLoadMore
            };

        case FindCardHolderConstant.GET_TYPES_AND_BANKS:
            return {
                ...state,
                bankTypes: action.bankTypes,
                specialBanks: action.specialBanks,
                getTypesAndBanksLoading: action.loading,
            };

        case FindCardHolderConstant.SELECT_BENEFICIARY_COUNTRY:
            return {
                ...state,
                selectedBeneficiaryCountry: action.data,
            };

        case FindCardHolderConstant.GET_BANKS:
            return {
                ...state,
                banks: action.data,
                banksMetaData: action.metaData,
                getBanksLoading: action.loading,
                banksLoadMoreLoading: false,
                banksCanLoadMore: true,
            };

        case FindCardHolderConstant.LOAD_MORE_BANKS:
            return {
                ...state,
                banks: [...state.banks, ...action.data],
                banksMetaData: action.metaData,
                banksLoadMoreLoading: action.loading,
                banksCanLoadMore: action.canLoadMore
            };

        case FindCardHolderConstant.SELECTED_BANK:
            return {
                ...state,
                selectedBank: action.data,
            };

        case FindCardHolderConstant.GET_BRANCHES:
            return {
                ...state,
                branches: action.data,
                branchesMetaData: action.metaData,
                getBranchesLoading: action.loading,
                branchesLoadMoreLoading: false,
                branchesCanLoadMore: true,
            };

        case FindCardHolderConstant.LOAD_MORE_BRANCHES:
            return {
                ...state,
                branches: [...state.branches, ...action.data],
                branchesMetaData: action.metaData,
                branchesLoadMoreLoading: action.loading,
                branchesCanLoadMore: action.canLoadMore
            };

        case FindCardHolderConstant.ADD_BENEFICIARY:
            return {
                ...state,
                addBeneficiaryLoading: action.loading,
            };

        case FindCardHolderConstant.DELETE_BENEFICIARY:
            return {
                ...state,
                deleteBeneficiaryLoading: action.loading,
            };

        case FindCardHolderConstant.EDIT_BENEFICIARY:
            return {
                ...state,
                editBeneficiaryLoading: action.loading,
            };

        case FindCardHolderConstant.GET_BANK_BY_IBAN_IFSC:
            return {
                ...state,
                getBankByIBANorIFSCLoading: action.loading,
            };

        case FindCardHolderConstant.GET_OTP_BENEFICIARY:
            return {
                ...state,
                getOTPBeneficiaryLoading: action.loading,
            };

        case FindCardHolderConstant.RESEND_OTP_BENEFICIARY:
            return {
                ...state,
                resendOTPBeneficiaryLoading: action.loading,
            };

        case FindCardHolderConstant.SEARCH_OLD_MOBILE_APP_USER:
            return {
                ...state,
                searchOldMobileAppUserLoading: action.loading,
                oldMobileAppUser: action.data,
            };

        case FindCardHolderConstant.SEARCH_NEW_MOBILE_APP_USER:
            return {
                ...state,
                searchNewMobileAppUserLoading: action.loading,
                newMobileAppUser: action.data
            };

        case FindCardHolderConstant.MERGE_MOBILE_APP_USERS:
            return {
                ...state,
                mergeMobileAppUsersLoading: action.loading,
            };
        case FindCardHolderConstant.GET_CLIENTS:
            return {
                ...state,
                clients : action.data,
                getClientLoading: action.loading,
            };

        case FindCardHolderConstant.GET_REFERENCE_INFO:
            return {
                ...state,
                referenceInfo: action.data,
                referenceInfoMetaData: action.metaData,
                getReferenceInfoloading: action.loading,
                referenceLoadMoreLoading: false,
                referenceCanLoadMore: true,
            };

        case FindCardHolderConstant.LOAD_MORE_BANKS:
            return {
                ...state,
                referenceInfo: [...state.referenceInfo, ...action.data],
                referenceInfoMetaData: action.metaData,
                referenceLoadMoreLoading: action.loading,
                referenceCanLoadMore: action.canLoadMore
            };


        default:
            return state;
    }
};
