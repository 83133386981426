import PROMOS from "../constants/Promos.constant";
import { encodeQueryData, handleError, handleSuccess } from "../../utils/methods";
import { post, get } from "../../utils/apiMethods";

export const getPromos = (payload = {}, CB) => (dispatch) => {
  const dispatchType = PROMOS.GET_PROMOS;
  dispatch({ type: dispatchType, loading: true });
  let queryParams = encodeQueryData(payload) ? `?${encodeQueryData(payload)}` : '';
  get(`employees/getPromoCodes${queryParams}`)
    .then(({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false, data: data?.data?.data, metaData: data?.data?.metaData });
        CB && CB();
      } else {
        dispatch({ type: dispatchType, loading: false, data: [] });
        handleError(data?.data?.message);
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false, data: [] });
      handleError(error?.data?.message || error?.message || "Something went wrong!");
    });
};

export const getCompanies = (payload = {}, CB) => (dispatch) => {
  const dispatchType = PROMOS.GET_COMPANIES;
  dispatch({ type: dispatchType, loading: true });
  post(`clients/getAll`, payload)
    .then(({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false, data: data?.data?.entries, metaData: data?.data?.metaData });
        CB && CB(data.data);
      } else {
        dispatch({ type: dispatchType, loading: false, data: [] });
        handleError(data?.data?.message);
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false, data: [] });
      handleError(error?.data?.message || error?.message || "Something went wrong!");
    });
};

export const clearUsers = () => (dispatch) => {
  const dispatchType = PROMOS.GET_USERS;
  dispatch({ type: dispatchType, loading: false, data: [], metaData: [] });
};

export const getUsers = (payload = {}) => (dispatch) => {
  const dispatchType = PROMOS.GET_USERS;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/getAll`, payload)
    .then(({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false, data: data?.data?.entries, metaData: data?.data?.metaData });
      } else {
        dispatch({ type: dispatchType, loading: false, data: [] });
        handleError(data?.data?.message);
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false, data: [] });
      handleError(error?.data?.message || error?.message || "Something went wrong!");
    });
};

export const getBillers = (payload = {}) => (dispatch) => {
  const dispatchType = PROMOS.GET_BILLERS;
  dispatch({ type: dispatchType, loading: true });
  let queryParams = encodeQueryData(payload) ? `?${encodeQueryData(payload)}` : '';
  get(`employees/getBillersByCountry${queryParams}`)
    .then(({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false, data: data?.data?.data, metaData: data?.data?.metaData });
      } else {
        handleError(data?.data?.message || "Something went wrong!");
        dispatch({ type: dispatchType, loading: false, data: [] });
        handleError(data?.data?.message);
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false, data: [] });
      handleError(error?.data?.message || error?.message || "Something went wrong!");
    });
};

export const clearBillers = () => (dispatch) => {
  const dispatchType = PROMOS.GET_BILLERS;
  dispatch({ type: dispatchType, loading: false, data: [], metaData: [] });
};

export const createPromo = (payload = {}, CB) => (dispatch) => {
  const dispatchType = PROMOS.CREATE_PROMO;
  dispatch({ type: dispatchType, loading: true });
  post(`employees/createPromoCode`, payload)
    .then(({ data }) => {
      if (!data.error) {
        dispatch({ type: dispatchType, loading: false, data: data?.data?.entries, metaData: data?.data?.metaData });
        handleSuccess(data?.data?.message);
        CB && CB();
      } else {
        dispatch({ type: dispatchType, loading: false, data: [] });
        handleError(data?.data?.message);
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false, data: [] });
      handleError(error?.data?.message || error?.message || "Something went wrong!");
    });
};

export const clearPromosApplicable = () => (dispatch) => {
  const dispatchType = PROMOS.GET_PROMOS_APPLICABLES;
  dispatch({ type: dispatchType, loading: false, data: [], metaData: [] });
};

export const getPromosApplicable = (payload = {}, CB) => (dispatch) => {
  const isLoadMore = payload?.page > 1;
  const dispatchType = isLoadMore ? PROMOS.LOAD_MORE_APPLICABLES : PROMOS.GET_PROMOS_APPLICABLES;
  dispatch({ type: dispatchType, loading: true, canLoadMore: true, data: [], metaData: {} });
  let queryParams = encodeQueryData(payload) ? `?${encodeQueryData(payload)}` : '';
  get(`employees/getPromoApplicable${queryParams}`)
    .then(({ data }) => {
      if (!data.error) {
        dispatch({
          type: dispatchType,
          loading: false,
          data: data?.data?.data,
          metaData: data?.data?.metaData,
          canLoadMore: data?.data?.metaData?.totalDocuments > data?.data?.data?.length,
        });
      } else {
        dispatch({ type: dispatchType, loading: false, data: [] });
        handleError(data?.data?.message);
      }
    })
    .catch((error) => {
      dispatch({ type: dispatchType, loading: false, data: [] });
      handleError(error?.data?.message || error?.message || "Something went wrong!");
    });
};