import { CButton } from "../../uiComponents";
import { EXCEL_FILETYPES } from "../../utils/constants";
import { readableTextCap } from "../../utils/methods";
import * as XLSX from "xlsx";

export const getBulkDeactivationTemplate = () => {
    let link = document.createElement("a");
    link.download = `employee-deactivation-template.csv`;
    link.href = `https://static-medias.s3.ap-southeast-1.amazonaws.com/Active-Deactive-Temp.xlsx`;
    link.click();
    URL.revokeObjectURL(link.href);
};

export const getLength = (data) => data?.length - 1;

export const renderSheet = (data, invalid, setIsOpenInvalid, loading) => {
    return data && data?.length ? (
        <div className="custom-table">
            <div className="d-flex justify-between" >
                <div className="d-flex align-items-start justify-center col">
                    <span>Valid Employees = <span>{getLength(data)}</span></span>
                    <span>Invalid Employees = <span>{getLength(invalid)}</span></span>
                </div>
                {
                    getLength(invalid) ?
                        <CButton
                            disabled={loading}
                            buttonLayout="red-dark"
                            title="Please check invalid employees"
                            containerClass="flex0 w-50"
                            id="submit"
                            type="submit"
                            onClick={() => setIsOpenInvalid(true)}
                        />
                        : null}
            </div>
            <div className="scroll-area" >
                <table>
                    {data?.map((item, i) => {
                        return (
                            <>
                                {item?.length ? (
                                    <tr>
                                        {item?.map((itm, index) => {
                                            if (i === 0) {
                                                return <th>{readableTextCap(itm)}</th>;
                                            }
                                            return <td>{itm}</td>;
                                        })}
                                    </tr>
                                ) : null}
                            </>
                        );
                    })}
                </table>
            </div>
        </div>
    ) : null;
};

export const arrayToBlob = async (responseArray) => {
    const modifyArray = responseArray.map(ra => ra?.map(i => { return { t: 's', v: i } }))
    let workSheet = XLSX.utils.aoa_to_sheet(modifyArray, { dateNF: "YYYY-MMM-DD" });
    let workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet1");
    let file = XLSX.write(workBook, { bookType: "xlsx", type: "array" });
    return await new Blob([file], { type: EXCEL_FILETYPES });
};

export const renderSalaryIssues = (invalid) => {
    return (
        <div className="custom-table">
            <div className="d-flex align-items-start justify-center col" >
                <span>Total Invalid Employees = <span>{getLength(invalid)}</span></span>
            </div>
            {getLength(invalid) ? <table className="block w-100" >
                {invalid?.map((item, i) => {
                    return (
                        <>
                            {item?.length ? (
                                <tr className="tr-height">
                                    {item?.map((itm, index) => {
                                        if (i === 0) {
                                            return <th>{readableTextCap(itm)}</th>;
                                        }
                                        return <td>{itm}</td>;
                                    })}
                                </tr>
                            ) : null}
                        </>
                    );
                }
                )}
            </table> : null}
        </div>
    )
};