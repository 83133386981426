import React from "react";
import { Image, Modal } from "antd";
import { readableTextCap } from "../../../utils/methods";
const success = require('../../../assets/images/accept-icon.png');
const error = require('../../../assets/images/error-icon.png');
const warning = require('../../../assets/images/warning-icon.png');

const CResult = ({ isOpen, setIsOpen, type = '', description }) => {

    const getImage = () => {
        if (type === 'success') {
            return success;
        }
        if (type === 'warning') {
            return warning;
        }
        if (type === 'error') {
            return error;
        }
    }

    return <>
        <Modal
            title=''
            width={450}
            centered
            open={isOpen}
            destroyOnClose={true}
            maskClosable={false}
            onCancel={() => setIsOpen(false)}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
        >
            <div className="result-container">
                <Image src={getImage()} className="result-image" preview={false} />
                <div className="result-body" >
                    <h1 className="result-title" >{readableTextCap(type)}</h1>
                    <p className="result-subtitle" >{description}</p>
                </div>
            </div>
        </Modal>
    </>
};

export default CResult;