import * as Yup from "yup";
import { phoneValidateNumberRegex } from "../../../utils/methods";

const ApplyAdvanceSalaryScheme = (payload) => Yup.object().shape({
  amount: Yup.number().required('Please enter amount')
    .integer('Please enter the amount as a decimal number')
    .min(payload.advance.min, payload.advance.minMessage)
    .max(payload.advance.max, payload.advance.maxMessage),

  firstReferenceRelation: Yup.string().required('Please select relationship'),
  firstReferenceFullName: Yup.string().required("Please enter name"),
  firstReferencePhone: Yup.string()
    .test('checkPhoneNumber', (value, obj) => phoneValidateNumberRegex({ ...payload?.inHomeCountry, cca2: payload?.inHomeCountry?.iso }, value || '', obj))
    .required("Please enter phone number"),

  localFriendRelation: Yup.string().required('Please select relationship'),
  localFriendFullName: Yup.string().required("Please enter name"),
  localFriendPhone: Yup.string()
    .test('checkPhoneNumber', (value, obj) => phoneValidateNumberRegex({ ...payload?.localCountry, cca2: payload?.localCountry?.iso }, value || '', obj))
    .test('matchPhoneNumber',
      "Phone number should not be similar to current user's phone number",
      (value) => {
        let perifix = payload?.localCountry?.code;
        let userTypedNumber = `${perifix ? perifix.replace(/[^\w\s]/gi, '') : ''}${value ? value.replace(/\s+/g, '') : ''}`;
        return payload?.currentUserNumber !== userTypedNumber
      }
    )
    .required("Please enter phone number"),
});

const OTPScheme = Yup.object().shape({
  otp: Yup.string().label("OTP").required('Please enter user OTP code').min(6, 'Please enter user OTP code').max(6, 'Please enter user OTP code'),
});

export { ApplyAdvanceSalaryScheme, OTPScheme };
