import React, { useState } from "react";
import { Avatar, List, Modal } from "antd";
import { searchView } from "../../../../utils/methods";
import { FaRegUserCircle } from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";
import { CButton } from "../../../../uiComponents";
import ReferedToOther from "./ReferedToOther";

const SelectReference = ({ isOpen, setIsOpen, data, keyVal = '', setKey, onSelect, employeeId, clientId }) => {

    const [search, setSearch] = useState('');
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [referencePhone, setReferencePhone] = useState('');

    const getData = () => {
        let filteredData = keyVal ? data[keyVal] : [];
        if (search?.length) {
            return filteredData?.filter(item => {
                let name = String(item?.firstReferenceFullName || item?.localFriendFullName).toLowerCase();
                let phone = String(item?.firstReferencePhone || item?.localFriendPhone).toLowerCase();
                let value = String(search)?.toLowerCase()
                if (name?.includes(value)) {
                    return item;
                } else if (phone?.includes(value)) {
                    return item;
                }
            })
        }
        return filteredData;
    }

    const onCancel = () => {
        setIsOpen(false)
        setKey('')
        setSearch('')
    }

    const toggleModal = (e, obj = {}) => {
        let phoneNumber = obj?.firstReferencePhone || obj?.localFriendPhone;
        e?.stopPropagation();
        setIsOpenModal(true)
        setReferencePhone(phoneNumber);
    }

    return (
        <Modal
            title='Select Reference'
            width={450}
            centered
            open={isOpen}
            destroyOnClose={true}
            maskClosable={false}
            onCancel={onCancel}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
        >
            {searchView(search, setSearch, 'margin-top_15 margin-bottom_15')}

            <InfiniteScroll
                className={`infinite-scroll`}
                dataLength={getData()?.length || 0}
                height={400}
                loader={false}
            >
                <List
                    dataSource={getData()}
                    className="ant-list-item-countries"
                    renderItem={(item) => {
                        return <List.Item
                            key={item?.firstReferencePhone}
                            onClick={() => onSelect(item)}
                        >
                            <List.Item.Meta
                                avatar={<Avatar shape="square" className="avatar-container" src={<FaRegUserCircle className="c-icon xx-large secondary margin_zero" />} />}
                                title={<div className="d-flex justify-between align-center" >
                                    <span>{item?.firstReferenceFullName || item?.localFriendFullName}</span>
                                    <span>({item?.firstReferenceRelation || item?.localFriendRelation})</span>
                                </div>}
                                description={<div className="d-flex justify-between align-center" >
                                    <span>{item?.firstReferencePhone || item?.localFriendPhone}</span>
                                    {/* <span onClick={toggleModal} >Referenced to Other</span> */}
                                    <CButton
                                        title="Referred to other"
                                        buttonLayout='small-invisible'
                                        containerClass='margin_zero flex0'
                                        size="small"
                                        // disabled={loading}
                                        // loading={loading}
                                        onClick={(e) => toggleModal(e, item)}
                                    />
                                </div>}
                            />
                        </List.Item>
                    }}
                />
            </InfiniteScroll>
            <ReferedToOther isOpen={isOpenModal} setIsOpen={setIsOpenModal} employeeId={employeeId} clientId={clientId} referencePhone={referencePhone} />
        </Modal>
    );
};
export default React.memo(SelectReference);