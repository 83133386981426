import React, { useState } from "react";
import { Alert, Descriptions, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ConditionalRendering, formatter } from "../../../utils/methods";
import { getAdvanceFees } from "./helper";
import { applyPromomAndOffer } from "../../../store/actions/Employees.action";
import { CButton, CField } from "../../../uiComponents";


const OverviewAdvanceSalary = ({ isOpen, setIsOpen, data, advanceSalaryDetails, employeeId, client, applyForAdvanceSalary }) => {

    const dispatch = useDispatch();

    const reduxState = useSelector(({ employees }) => {
        return {
            loading: employees.applyAdvanceSalaryLoading,
            applyPromoLoading: employees.applyPromoLoading,
            promoAndOffer: employees.promoAndOffer,
        };
    });

    const [promo, setPromo] = useState('');
    const [appliedPromo, setAppliedPromo] = useState({});
    const [promoError, setPromoError] = useState('');

    const generateTransferAmountAndCharges = () => {
        let info = [];
        let obj = {
            ...(data && data),
            ...(advanceSalaryDetails && advanceSalaryDetails)
        };
        let feeBracket = getAdvanceFees(obj?.feesBrackets, Number(obj?.amount));

        if (Number(obj?.amount)) {
            info.push({
                name: 'Advance Amount',
                value: formatter(Number(obj?.amount), 'AED')
            });
        }
        if (feeBracket?.platformFee) {
            info.push({
                name: 'Platform Fee',
                value: formatter(feeBracket?.platformFee - feeBracket?.platformFeeVat, 'AED')
            });
        }
        if (feeBracket?.fees) {
            info.push({
                name: "Processing Fees",
                value: formatter(feeBracket?.fees - feeBracket?.vatAmount, 'AED')
            });
        }
        if (feeBracket?.vatAmount) {
            info.push({
                name: 'Vat',
                value: formatter(feeBracket?.vatAmount + feeBracket?.platformFeeVat, 'AED')
            });
        }
        if (appliedPromo && appliedPromo?.promoDetails?.promo) {
            info.push({
                name: 'Promo Code',
                value: appliedPromo?.promoDetails?.promo
            });
        }
        let offer = appliedPromo && appliedPromo?.mode ? appliedPromo : reduxState?.promoAndOffer;

        if (offer?.mode && offer?.discountAmount) {
            info.push({
                name: offer?.mode === 'DISCOUNT' ? 'Discount' : 'Cashback',
                value: `${formatter(offer?.discountAmount)}` || 0
            })
        }

        return info
    };

    const applyPromoCodeCallback = (res) => {
        if (!res?.error) {
            if (res?.data?.promoError) {
                setPromoError(res?.data?.promoError || '')
            }
            setAppliedPromo(res?.data)
        }
        else {
            setAppliedPromo(null);
            // setPromo('');
        }
    };

    const applyPromoFunc = (promocode) => {
        checkPromoCodeAndOffer(promocode);
    };

    const applyPromo = () => {
        if (promo) {
            applyPromoFunc(promo)
        }
    };

    const removePromo = () => {
        applyPromoFunc('');
        setPromoError('');
        setPromo('');
    };

    const checkPromoCodeAndOffer = (promo) => {
        const payload = {
            employeeId,
            client,
            ...(promo && promo?.length && { promocode: promo }),
            amount: data?.amount
        }
        dispatch(applyPromomAndOffer(payload, applyPromoCodeCallback));
    }

    return <Modal
        title="Advance Salary"
        width={700}
        centered
        open={isOpen}
        destroyOnClose={true}
        maskClosable={false}
        closable={false}
        onCancel={() => !reduxState?.loading && setIsOpen(false)}
        okButtonProps={{
            title: 'Verify',
            onClick: () => applyForAdvanceSalary(),
            disabled: reduxState?.applyPromoLoading || reduxState?.loading,
            loading: reduxState?.loading,
            className: 'secondary-back'
        }}
        okText='Submit'
        cancelButtonProps={{ disabled: reduxState?.loading || reduxState?.applyPromoLoading }}
    >
        <div className="margin-top_20" />
        <Descriptions title="Detail & Information" layout="horizontal" bordered column={1} >
            <Descriptions.Item label={`${data?.firstReferenceRelation} Full Name`}>{data?.firstReferenceFullName}</Descriptions.Item>
            <Descriptions.Item label={`${data?.firstReferenceRelation} Phone Number`}>{data?.firstReferencePhone}</Descriptions.Item>
            <Descriptions.Item label={`${data?.localFriendRelation} Full Name`}>{data?.localFriendFullName}</Descriptions.Item>
            <Descriptions.Item label={`${data?.localFriendRelation} Phone Number`}>{data?.localFriendPhone}</Descriptions.Item>
        </Descriptions>

        <ConditionalRendering
            condition={reduxState?.promoAndOffer?.offerDetails?.title}
            children={<Alert banner message={reduxState?.promoAndOffer?.offerDetails?.title} type="success" className="margin-top_20 alert-box" />}
        />

        <div className="margin-top_20" />
        <Descriptions title="Advance Salary & Charges" layout="horizontal" bordered column={1} >
            {generateTransferAmountAndCharges().map((o, i) => {
                return <Descriptions.Item key={i} label={o?.name}>{o?.value}</Descriptions.Item>
            })}
        </Descriptions>
        <div className="d-flex w-100 margin-top_15" >
            <CField
                disabled={(reduxState?.applyPromoLoading || reduxState?.loading) || appliedPromo?.promoDetails?.promo}
                containerClass='w-100'
                name="promo"
                isLabel={false}
                placeholder="Enter Promo Code"
                type="text"
                error={promoError}
                value={promo}
                onBlur={() => {
                    appliedPromo?.promoDetails?.promo ? removePromo() : applyPromo()
                }}
                onChange={(e) => {
                    setPromoError('');
                    setPromo(e.target.value)
                }}
            />
            <CButton
                buttonLayout='secondary'
                containerClass='flex0 margin-right_zero'
                title={reduxState?.applyPromoLoading ? '' : appliedPromo?.promoDetails?.promo ? 'Remove' : 'Apply'}
                loading={reduxState?.applyPromoLoading}
                disabled={reduxState?.applyPromoLoading || reduxState?.loading}
                id='apply'
                onClick={() => appliedPromo?.promoDetails?.promo ? removePromo() : applyPromo()}
            />

        </div>
    </Modal>
};

export default OverviewAdvanceSalary;