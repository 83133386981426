import { lazy } from "react";

const Login = lazy(() => import("./auth/login/Login"));
const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const Approval = lazy(() => import("./approval/Approval"));
const ExchangeHouseApproval = lazy(() => import("./exchangeApproval/Approval"));
const OnboardApprovals = lazy(() => import("./onboardApproval/Approval"));
const AddEmployer = lazy(() => import("./employer/add/Add"));
const Employer = lazy(() => import("./employer/Employer"));
const FullView = lazy(() => import("./employer/details/FullDetails"));
const Admin = lazy(() => import("./admin/Admin"));
const ManageAccess = lazy(() => import("./admin/Manage"));
const Employee = lazy(() => import("./employees/Employees"));
const AddEmployee = lazy(() => import("./employees/add/Add"));
const EmployeeDetailsUpdated = lazy(() => import("./employees/updatedDetails/Details"));
const CentivCard = lazy(() => import("./centivCard/CentivCard"));
const Salaries = lazy(() => import("./salaries/Salaries"));
const CentivCardDetails = lazy(() => import("./centivCard/CentivCardDetails"));
const Transactions = lazy(() => import("./transactions/Transactions"));
const AllTransactions = lazy(() => import("./employees/updatedDetails/AllTransactions"));
const ExchangeHouse = lazy(() => import("./exchangeHouse/ExchangeHouse"));
const Branches = lazy(() => import("./branches/Branches"));
const Profile = lazy(() => import("./profile/Profile"));
const AdvanceSalary = lazy(() => import("./advanceSalary/AdvanceSalary"));
const ManageUsers = lazy(() => import("./manageUsers/manageUsers"));
const SalaryStatus = lazy(() => import("./salaryStatus/SalaryStatus"));
const Marketplace = lazy(() => import("./marketplace/Marketplace"));
const MarketplaceCategories = lazy(() => import("./marketplace/categories/Categories"));
const MarketplaceSuppliers = lazy(() => import("./marketplace/suppliers/Suppliers"));
const MarketplaceAddProduct = lazy(() => import("./marketplace/products/add/Add"));
const MarketplaceProductView = lazy(() => import("./marketplace/products/view/View"));
const MarketplaceOrders = lazy(() => import("./marketplace/orders/Orders"));
const FindCardHolder = lazy(() => import("./findCardHolder"));
const Remittances = lazy(() => import("./remittances"));
const Logs = lazy(() => import("./logs"));
const ReplacementCards = lazy(() => import("./replacementCards"));
const Otp = lazy(() => import("./auth/otp/Otp"));
const AuthTwoFactor = lazy(() => import("./auth/authenticationTwoFactor/authTwoFactor"));
const EmployerTeamMembers = lazy(() => import("./employer/teamMembers"));
const ManageAccessTeamMember = lazy(() => import("./employer/teamMembers/Manage"));
const TransactionsHistory = lazy(() => import('./transactionsHistory'))
const ScratchedCards = lazy(() => import('./scratchedCards'))
const LoginHistory = lazy(() => import('./loginHistory'))
const EditEmployer = lazy(() => import('./employer/edit'))
const Promos = lazy(() => import('./promos'))
const Offers = lazy(() => import('./offers'))
const MobileAppApproval = lazy(() => import('./mobileAppApprovals/index'))
const TransactionScreening = lazy(() => import('./compliance/transactionScreening'));
const BeneficiaryScreening = lazy(() => import('./compliance/beneficiaryScreening'));
const MobilePromotions = lazy(() => import('./mobilePromotions'))
const DeliveryModule = lazy(() => import('./deliveryModule'))
const HelloPaisaFee = lazy(() => import('./helloPaisaFee'))
const MobileAppLogsModule = lazy(() => import('./mobileAppLogs'));
const RemittanceRate = lazy(() => import('./remittanceRate'))

export {
  Login,
  Otp,
  AuthTwoFactor,
  Dashboard,
  Employer,
  AddEmployer,
  SalaryStatus,
  Profile,
  AllTransactions,
  Transactions,
  ExchangeHouseApproval,
  OnboardApprovals,
  Approval,
  Admin,
  Employee,
  Salaries,
  AddEmployee,
  ManageAccess,
  EmployeeDetailsUpdated,
  CentivCard,
  CentivCardDetails,
  FullView,
  ExchangeHouse,
  Branches,
  AdvanceSalary,
  ManageUsers,
  Marketplace,
  MarketplaceCategories,
  MarketplaceSuppliers,
  MarketplaceAddProduct,
  MarketplaceProductView,
  MarketplaceOrders,
  FindCardHolder,
  Remittances,
  Logs,
  ReplacementCards,
  EmployerTeamMembers,
  ManageAccessTeamMember,
  TransactionsHistory,
  ScratchedCards,
  LoginHistory,
  EditEmployer,
  Promos,
  Offers,
  MobileAppApproval,
  TransactionScreening,
  BeneficiaryScreening,
  MobilePromotions,
  DeliveryModule,
  HelloPaisaFee,
  MobileAppLogsModule,
  RemittanceRate
};
