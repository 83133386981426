import React from "react";
import { CButton } from "../../uiComponents";
import { ConditionalRendering } from "../../utils/methods";
import { useNavigate } from "react-router";
import { IoIosCash } from "react-icons/io";
import { MOBILE_USER_STATUS } from "../../utils/constants";

const CCNPL = ({ employee, mobileUser, client, canView }) => {

    const navigate = useNavigate();

    return <>
        <ConditionalRendering
            condition={mobileUser?.status === MOBILE_USER_STATUS?.ACTIVE && !mobileUser?.isLightUser && canView}
            children={<CButton
                containerClass="flex0"
                buttonType="gradient"
                title="CNPL"
                onClick={() => navigate('/cnpl', { state: { employee, client } })}
                leftIcon={(className) => <IoIosCash className={className} />}
            />}
        />
    </>
};

export default CCNPL;