import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import { getBranches } from "../../../store/actions/FindCardHolder.action";
import { ConditionalRendering } from "../../../utils/methods";
import SelectCountry from "./SelectCountry";
import SelectTypesAndBanks from "./SelectTypesAndBanks";
import SelectBank from "./SelectBank";
import SelectBranch from "./SelectBranch";
import IBANorIFSC from "./IBANorIFSC";
import InputForm from "./InputForm";
import { ImArrowLeft2 } from "react-icons/im";
import { isIndia, isPakistan } from "./helper";

const Add = ({ isOpen, setIsOpen, get, client, employee }) => {

    const dispatch = useDispatch();

    const reduxState = useSelector(({ findCardHolder }) => {
        return {
            loading: findCardHolder?.getCountriesLoading || findCardHolder?.getTypesAndBanksLoading || findCardHolder?.getBanksLoading || findCardHolder?.getBranchesLoading || findCardHolder?.addBeneficiaryLoading || findCardHolder?.getBankByIBANorIFSCLoading || findCardHolder.getOTPBeneficiaryLoading,
        };
    });

    const { loading } = reduxState;

    const [selected, setSelected] = useState({});
    const [inDisplay, setInDisplay] = useState([1]);

    useEffect(() => {
        if (!isOpen) {
            setInDisplay([1]);
            setSelected({});
        }
    }, [isOpen]);

    const isInDisplay = (page) => inDisplay[inDisplay.length - 1] === page;

    const activeDispaly = inDisplay.slice(-1)[0];

    const getBranch = (bank) => {
        const payload = {
            page: 1,
            limit: 10,
            country: selected?.country?.Name,
            bankType: selected?.bankType?.BankType ? selected?.bankType?.BankType : selected?.bankType?.Value,
            ...(bank?.BankName && { bankName: bank?.BankName })
        };
        dispatch(getBranches(payload, getBranchCallBack));

    };

    const getBranchCallBack = (res) => {
        if (res?.length > 1) {
            setInDisplay([1, 2, 3, 5]);
        } else {
            setInDisplay([1, 2, 3, 6]);
        }
    }

    const callBack = () => {
        setIsOpen(false);
        get();
    }

    const onSelect = (item, page, iban = '') => {
        switch (page) {
            case 1:
                setSelected({ ...selected, country: item });
                setInDisplay([1, 2]);
                break;
            case 2:
                if (item?.BankType === 'BankAccount') {
                    setSelected({ ...selected, bankName: item?.BankName, bank: item });
                    setInDisplay([1, 2, 6]);
                } else {
                    if (item?.Value === 'BankAccount' && (isPakistan(selected?.country?.CCA2 || selected?.country?.cca2) || isIndia(selected?.country?.CCA2 || selected?.country?.cca2))) {
                        setSelected({ ...selected, bankType: item });
                        setInDisplay([1, 2, 3, 4]);
                    } else {
                        setSelected({ ...selected, bankType: item });
                        setInDisplay([1, 2, 3]);
                    }
                }
                break;
            case 3:
                getBranch(item);
                setSelected({ ...selected, bankName: item?.BankName, bank: item });
                break;
            case 4:
                setSelected({ ...selected, bankName: item?.BankName, bank: item, IBAN: iban });
                setInDisplay([1, 2, 3, 4, 6]);
                break;
            case 5:
                setSelected({ ...selected, bankName: item?.BankName, bank: item });
                setInDisplay([1, 2, 3, 5, 6]);
                break;
            default:
                break;
        };
    }

    const goBack = () => {
        if (!loading) {
            let selectedReplica = selected;
            setInDisplay(inDisplay => inDisplay.slice(0, -1));
            const previousScreen = inDisplay[inDisplay.length - 1];
            switch (previousScreen) {
                case 2:
                    delete selectedReplica.bankName;
                    delete selectedReplica.bankType;
                    delete selectedReplica.bank;
                    setSelected(selectedReplica);
                    break;
                case 3:
                    delete selectedReplica.bank;
                    delete selectedReplica.bankName;
                    setSelected(selectedReplica);
                    break;
                default:
                    break;
            }
        }
    }

    const renderGoBack = () => {
        const active = activeDispaly;
        if ([2, 3, 4, 5, 6].includes(active)) {
            return <ImArrowLeft2 className="c-icon margin-left_zero" onClick={goBack} />
        }
        return null;
    }

    const renderTitle = () => {
        const active = activeDispaly;
        let title = '';
        switch (active) {
            case 1:
                title = 'Popular Countries'
                break;
            case 2:
                title = 'How does your receiver want the money?'
                break;
            case 3:
                title = 'Select Bank'
                break;
            case 4:
                title = `Enter ${isPakistan(selected?.country?.CCA2 || selected?.country?.cca2) ? 'IBAN' : 'IFSC'}`
                break;
            case 5:
                title = 'Select Branch'
                break;
            case 6:
                title = 'Add Beneficiary'
                break;
            default:
                break;
        }
        return <span className="d-flex" >{renderGoBack()}{title}</span>
    }

    return (
        <Modal
            width={450}
            title={renderTitle()}
            centered
            open={isOpen}
            maskClosable={false}
            closable={!loading}
            onCancel={() => setIsOpen(false)}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose
        >

            <ConditionalRendering
                condition={isInDisplay(1)}
                children={<SelectCountry
                    onSelect={onSelect}
                    client={client}
                    employee={employee}
                />}
            />

            <ConditionalRendering
                condition={isInDisplay(2)}
                children={<SelectTypesAndBanks
                    onSelect={onSelect}
                    country={selected?.country}
                />}
            />

            <ConditionalRendering
                condition={isInDisplay(3)}
                children={<SelectBank
                    onSelect={onSelect}
                    selectedData={selected}
                />}
            />

            <ConditionalRendering
                condition={isInDisplay(4)}
                children={<IBANorIFSC
                    onSelect={onSelect}
                    goBack={() => goBack()}
                    selectedData={selected}
                />}
            />

            <ConditionalRendering
                condition={isInDisplay(5)}
                children={<SelectBranch
                    onSelect={onSelect}
                    selectedData={selected}
                />}
            />

            <ConditionalRendering
                condition={isInDisplay(6)}
                children={<InputForm
                    onSelect={onSelect}
                    selectedData={selected}
                    callBack={callBack}
                    client={client}
                    employee={employee}
                />}
            />

        </Modal>
    );
};

export default React.memo(Add);
