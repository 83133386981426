import { post } from "../../utils/apiMethods";
import { handleError } from "../../utils/methods";
import ADVANCE_SALARY from "../constants/AdvanceSalary.constant";

export const getAdvanceSalary = (payload) => (dispatch) => {
    dispatch({ type: ADVANCE_SALARY.ADVANCE_SALARY_GET_API, loading: true });
    post(`clients/getAdvanceSalary`, payload)
        .then(({ data }) => {
            if (!data.error) {
                dispatch({
                    type: ADVANCE_SALARY.ADVANCE_SALARY_GET_API,
                    loading: false,
                    data: data?.data?.entries,
                    metaData: data?.data?.metaData,
                });
            } else {
                dispatch({ type: ADVANCE_SALARY.ADVANCE_SALARY_GET_API, loading: false, data: [], metaData: {} });
                handleError(data?.data?.message || "Something went wrong!");
            }
        })
        .catch((error) => {
            dispatch({ type: ADVANCE_SALARY.ADVANCE_SALARY_GET_API, loading: false, data: [], metaData: {} });
            handleError(error?.data?.message || error?.response?.data?.message || "Something went wrong!");
        });
};

export const getEmpAdvanceSalary = (payload) => (dispatch) => {
    dispatch({ type: ADVANCE_SALARY.EMP_ADVANCE_SALARY_GET_API, loading: true });
    post(`clients/getAdvanceSalary`, payload)
        .then(({ data }) => {
            if (!data.error) {
                dispatch({
                    type: ADVANCE_SALARY.EMP_ADVANCE_SALARY_GET_API,
                    loading: false,
                    data: data?.data?.entries,
                    metaData: data?.data?.metaData,
                });
            } else {
                dispatch({ type: ADVANCE_SALARY.EMP_ADVANCE_SALARY_GET_API, loading: false, data: [], metaData: {} });
                handleError(data?.data?.message || "Something went wrong!");
            }
        })
        .catch((error) => {
            dispatch({ type: ADVANCE_SALARY.EMP_ADVANCE_SALARY_GET_API, loading: false, data: [], metaData: {} });
            handleError(error?.data?.message || error?.response?.data?.message || "Something went wrong!");
        });
};
