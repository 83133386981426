import CENTIV from "../constants/CentivCard.constant";

const initialState = {
  cardTransactionData: [],
  transactionLoading: false,
  centivTableData: [],
  tableLoading: false,
  tableMetaData: {},
  centivEmployees: [],
  centivEmployeesLoading: false,
  overAllData: [],
  overAllDataLoading: [],
  loadFundsLoading: false,
  downloadStatementLoading: false,

  syncLoading: false,

  spendings: [],
  getSpendingsLoading: false,
  loadingId: '',

  uploadNoteReceiptLoading: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case CENTIV.GET_CARD_TRANSACTION_DATA:
      return {
        ...state,
        cardTransactionData: action.data,
        transactionLoading: action.loading,
      };
    case CENTIV.GET_CENTIV_BALANCE:
      return {
        ...state,
        centivEmployees: action.data,
        centivEmployeesLoading: action.loading,
      };
    case CENTIV.GET_CENTIV_TABLE:
      return {
        ...state,
        centivTableData: action.data || state.centivTableData,
        tableLoading: action.loading,
        tableMetaData: action.metaData
      };
    case CENTIV.GET_OVERALL_DATA:
      return {
        ...state,
        overAllData: action.data,
        overAllDataLoading: action.loading,
      };
    case CENTIV.LOAD_FUNDS:
      return {
        ...state,

        loadFundsLoading: action.loading,
      };
    case CENTIV.GET_STATEMEMNT_FILE:
      return {
        ...state,

        downloadStatementLoading: action.loading,
      };

    case CENTIV.SYNC_TRANSACTIONS:
      return {
        ...state,
        syncLoading: action.loading,
      };

    case CENTIV.GET_SPENDINGS:
      return {
        ...state,
        spendings: action.data,
        getSpendingsLoading: action.loading,
        loadingId: action.loadingId,
      };

    case CENTIV.UPLOAD_NOTE_RECEIPT:
      return {
        ...state,
        uploadNoteReceiptLoading: action.loading,
      };


    default:
      return state;
  }
};
