import { Tag } from "antd";

export const renderTag = (val) => {
    let color = '';
    switch (val) {
        case 'Pending':
            color = 'orange';
            break;
        case 'Reject':
        case 'No':
            color = 'red';
            break;
        case 'Approve':
        case 'Yes':
            color = 'green';
            break;
        default:
            break;
    }
    return <Tag color={color}>{val}</Tag>
}