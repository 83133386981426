import React, { useEffect, useState } from "react";
import { Alert, Image, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getAdvanceSalaryDetails } from "./helper";
import { ConditionalRendering } from "../../../utils/methods";
import FormAdvanceSalary from "./FormAdvanceSalary";
import OverviewAdvanceSalary from "./OverviewAdvanceSalary";
import { applyAdvanceSalary, applyPromomAndOffer, verifyAdvSalary } from "../../../store/actions/Employees.action";
import OTPConfirmation from "./OTPConfirmation";
import ReceiptModalContainer from "./ReceiptModalContainer";
import CResult from "./CResult";

const MainModal = ({ isOpen, setIsOpen, employee, client }) => {

    const dispatch = useDispatch();

    const reduxState = useSelector(({ employees }) => {
        return {
            loading: employees.applyAdvanceSalaryLoading,
            applyPromoLoading: employees.applyPromoLoading,
            advanceSalaryDetails: employees.checkAdvanceSalaryEligibilityResponse,
            promoAndOffer: employees.promoAndOffer,
        };
    });

    const { loading, advanceSalaryDetails, promoAndOffer } = reduxState;

    const [isOpenOverview, setIsOpenOverview] = useState(false);
    const [isOpenOTP, setIsOpenOTP] = useState(false);
    const [inputValues, setInputValues] = useState({});
    const [localCountry, setLocalCountry] = useState(null);
    const [inHomeCountry, setInHomeCountry] = useState(null);
    const [holdingAdvSalaryRes, setHoldingAdvSalaryRes] = useState({});
    const [isOpenReceipt, setIsOpenReceipt] = useState(false);
    const [isOpenResult, setIsOpenResult] = useState(false);
    const [resVerifyAdvSal, setResVerifyAdvSal] = useState({});

    useEffect(() => {
        if (isOpen && advanceSalaryDetails?.status === '01') {
            checkPromoCodeAndOffer();
        }
    }, [isOpen, advanceSalaryDetails]);

    const checkPromoCodeAndOffer = () => {
        const payload = {
            employeeId: employee?._id,
            client: client?._id,
            amount: inputValues?.amount
        }
        dispatch(applyPromomAndOffer(payload));
    }

    const onFinish = (values) => {
        const inHomeCountryCode = inHomeCountry?.code?.replace('+', '');
        const localCountryCode = localCountry?.code?.replace('+', '');
        const payload = {
            ...values,
            firstReferencePhone: `${inHomeCountryCode}${values?.firstReferencePhone}`,
            localFriendPhone: `${localCountryCode}${values?.localFriendPhone}`,
        }
        setInputValues(payload);
        setIsOpenOverview(true);
    }

    const callBackApplyAdvanceSalary = (res) => {
        setHoldingAdvSalaryRes(res);
        setIsOpenOTP(true);
    }

    const applyForAdvanceSalary = (isTermsChecked = false) => {
        if (isTermsChecked) {
            const payload = {
                employeeId: employee?._id,
                client: client?._id,
                ...(promoAndOffer?.promoDetails?.promo && { promocode: promoAndOffer?.promoDetails?.promo }),
                ...inputValues,
            }
            dispatch(applyAdvanceSalary(payload, callBackApplyAdvanceSalary))
        } else {
            setIsOpenReceipt(true);
        }
    };

    const callBackOTPVerification = (res) => {
        const payload = {
            employeeId: employee?._id,
            client: client?._id,
            token: res?.token,
        }
        dispatch(verifyAdvSalary(payload, callBackVerifyAdvanceSalary))
    };

    const callBackVerifyAdvanceSalary = (res) => {
        setResVerifyAdvSal(res)
        setIsOpenResult(true)
        setIsOpen(false);
        setIsOpenOverview(false);
        setIsOpenOTP(false);
    };

    return <>
        <Modal
            title="Apply for Advance Salary"
            width={450}
            centered
            open={isOpen}
            destroyOnClose={true}
            maskClosable={false}
            onCancel={() => !loading && setIsOpen(false)}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
        >
            <ConditionalRendering
                condition={promoAndOffer?.offerDetails?.title && advanceSalaryDetails?.status === '01'}
                children={<Alert banner message={promoAndOffer?.offerDetails?.title} type="success" className="margin-top_20 margin-bottom_15 alert-box" />}
            />
            <ConditionalRendering
                condition={advanceSalaryDetails?.status === '01'}
                children={<FormAdvanceSalary
                    setIsOpen={setIsOpen}
                    loading={reduxState?.loading}
                    employee={employee}
                    client={client}
                    advanceSalaryDetails={advanceSalaryDetails}
                    localCountry={localCountry}
                    setLocalCountry={setLocalCountry}
                    inHomeCountry={inHomeCountry}
                    setInHomeCountry={setInHomeCountry}
                    onFinish={onFinish} />}
                elseChildren={<ConditionalRendering
                    condition={advanceSalaryDetails}
                    children={<div className="result-container">
                        <Image src={getAdvanceSalaryDetails(advanceSalaryDetails)?.image} className="result-image" preview={false} />
                        <div className="result-body" >
                            <h1 className="result-title" >{getAdvanceSalaryDetails(advanceSalaryDetails)?.badge}</h1>
                            <p className="result-subtitle" >{getAdvanceSalaryDetails(advanceSalaryDetails)?.subTitle}</p>
                        </div>
                    </div>}
                />
                }
            />
        </Modal>
        <OverviewAdvanceSalary
            isOpen={isOpenOverview}
            setIsOpen={setIsOpenOverview}
            advanceSalaryDetails={advanceSalaryDetails}
            applyForAdvanceSalary={applyForAdvanceSalary}
            data={inputValues}
            employeeId={employee?._id}
            client={client}
        />
        <OTPConfirmation
            isOpen={isOpenOTP}
            setIsOpen={setIsOpenOTP}
            employeeId={employee?._id}
            client={client}
            holdingAdvSalaryRes={holdingAdvSalaryRes}
            callBack={callBackOTPVerification}
        />
        <ReceiptModalContainer
            isOpen={isOpenReceipt}
            setIsOpen={setIsOpenReceipt}
            employee={employee}
            advanceSalaryDetails={advanceSalaryDetails}
            applyForAdvanceSalary={applyForAdvanceSalary}
            loading={loading}
        />
        <CResult
            isOpen={isOpenResult}
            setIsOpen={setIsOpenResult}
            type={resVerifyAdvSal?.type}
            description={resVerifyAdvSal?.description}
        />
    </>
};

export default MainModal;
