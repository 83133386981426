import TRANSACTION_SCREENING from "./../constants/TransactionScreening.constant";

const initialState = {
    getTransactionScreeningLoading: false,
    transactionScreening: [],
    transactionScreeningMetaData: {},
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case TRANSACTION_SCREENING.GET_TRANSACTION_SCREENING:
            return {
                ...state,
                getTransactionScreeningLoading: action.loading,
                transactionScreening: action.data,
                transactionScreeningMetaData: action.metaData
            };

        default:
            return state;
    }
};
