import TEAM_MEMBERS from "../constants/EmployerTeamMembers.constant";

const initialState = {
    teamMembers: [],
    loading: true,
    metaData: {},
    addLoading: false,
    updateLoading: false,
    toggleStatusLoading: false,
    allClientChildrens: [],
    getAllClientChildrensLoading: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case TEAM_MEMBERS.TEAM_MEMBERS_GET_API:
            return {
                ...state,
                teamMembers: action.data,
                metaData: action.metaData,
                loading: action.loading
            };

        case TEAM_MEMBERS.TEAM_MEMBER_ADD_API:
            return { ...state, addLoading: action.loading };

        case TEAM_MEMBERS.TEAM_MEMBER_UPDATE_API:
            return { ...state, updateLoading: action.loading };

        case TEAM_MEMBERS.TEAM_MEMBER_TOGGLE_STATUS:
            return { ...state, toggleStatusLoading: action.loading };

        case TEAM_MEMBERS.GET_ALL_CLIENTS_CHILDRENS:
            return {
                ...state,
                allClientChildrens: action.data,
                getAllClientChildrensLoading: action.loading,
            };

        default:
            return state;
    }
};
