import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CButton } from "../../../uiComponents";
import { checkAdvanceSalaryEligibility } from "../../../store/actions/Employees.action";
import { ConditionalRendering } from "../../../utils/methods";
import MainModal from './MainModal';
import { MOBILE_USER_STATUS } from "../../../utils/constants";

const CApplyAdvanceSalary = ({ employee, client, mobileUser, noGradient }) => {

    const dispatch = useDispatch();

    const reduxState = useSelector(({ employees }) => {
        return {
            loading: employees.checkAdvanceSalaryEligibilityLoading,
        };
    });

    const { loading } = reduxState;

    const [isOpen, setIsOpen] = useState(false);

    const checkEligibilityCallBack = () => {
        setIsOpen(true);
    }

    const checkAdvSalaryEligibility = () => {
        const payload = {
            employeeId: employee?._id,
            client: client?._id,
            force: true,
        }
        dispatch(checkAdvanceSalaryEligibility(payload, checkEligibilityCallBack));
    }

    return <>
        <ConditionalRendering
            condition={mobileUser?.status === MOBILE_USER_STATUS?.ACTIVE && !mobileUser?.isLightUser}
            children={
                <CButton
                    buttonLayout="primary-outline"
                    containerClass="flex0"
                    buttonType={noGradient ? "" : "gradient"}
                    title="Apply Advance Salary"
                    onClick={checkAdvSalaryEligibility}
                    loading={loading}
                    disabled={loading}
                    leftIcon="add"
                />        
             }
        />
        <MainModal isOpen={isOpen} setIsOpen={setIsOpen} employee={employee} client={client}  />
    </>
};

export default CApplyAdvanceSalary;