const RemittanceRateConstant = {

    GET_REMITTANCE_RATE_LIST : 'GET_REMITTANCE_RATE_LIST',

    ADD_EDIT_REMITTANCE_RATE_LIST : 'ADD_EDIT_REMITTANCE_RATE_LIST',

    GET_COUNTRY_WITH_COUNTRY_CODE : 'GET_COUNTRY_WITH_COUNTRY_CODE'
};

export default RemittanceRateConstant;
