const PROMOS = {
    GET_PROMOS: "GET_PROMOS",
    
    GET_COMPANIES : "GET_COMPANIES",
    
    GET_USERS : "GET_USERS",
    
    GET_BILLERS :  "GET_BILLERS",
    
    CREATE_PROMO : "CREATE_PROMO",
    
    GET_PROMOS_APPLICABLES : 'GET_PROMOS_APPLICABLES',

    LOAD_MORE_APPLICABLES : 'LOAD_MORE_APPLICABLES'
};

export default PROMOS;