import React from 'react';
import { MappedElement, renderDate } from '../../../utils/methods';

const styles = {
    container: {
        overflow: 'scroll',
        margin: 0,
        width: '100%',
        height: '100%',
        fontFamily: 'Poppins, sans-serif',
    },
    body: {
        margin: 0,
        display: 'flex',
        width: '100%',
        height: '100%',
        fontFamily: 'Poppins, sans-serif',
    },
    p: {
        margin: 0,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        marginTop: 20,
        marginInline: 0,
        marginBottom: 15,
    },
    logoContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'flex-start',
    },
    logoContainerImg: {
        width: 200,
        height: 100,
        objectFit: 'contain',
    },
    pageFooter: {
        marginTop: 20,
        paddingTop: 10,
        width: '100%',
        background: 'white',
    },
    contactInfoList: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: 5,
    },
    contactInfoListItem: {},
    contactInfoListItemSpan: {
        fontSize: 12,
        color: '#000000',
    },
    contactInfoListItemSpanB: {
        color: '#ec6f30',
    },
    contactInfoListItemA: {
        fontSize: 12,
        color: '#000000',
        textDecoration: 'none',
        color: '-webkit-link',
        textDecoration: 'underline',
    },
    tr: {
        pageBreakInside: 'avoid',
        pageBreakAfter: 'auto',
    },
    pageHeader: {
        height: 50,
        position: 'fixed',
        top: '0mm',
        width: '100%',
    },
    pageFooterSpace: {
        height: 100,
    },
    pageFooterContent: {
        display: 'flex',
        flexDirection: 'row',
    },
    '@page': {
        size: 'A4',
        margin: 0,
    },
    table: {
        width: '100%',
        padding: '35px',
    },
    boldText: {
        fontWeight: 700,
    },
    darkText: {
        color: 'black',
    },
    kfcTitle: {
        fontSize: '14px',
        lineHeight: '20px',
        textAlign: 'center',
        fontWeight: '700',
        marginBottom: '15px',
    },
    kfcParagraph: {
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: '400',
        marginBottom: 0,
    },
    tableSection: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'black',
    },
    tableSectionHeader: {
        backgroundColor: 'red',
    },
    tableSectionHeaderText: {
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: '700',
        color: 'white',
        padding: '5px',
        marginBottom: 0,
    },
    tableSectionBody: {},
    tableSectionBodyItem: {
        display: 'flex',
        flexDirection: 'row',
        borderTop: '1px solid black',
    },
    tableSectionBodyItemLeft: {
        flex: '0.4',
        padding: '5px',
        borderRight: '1px solid black',
    },
    tableSectionBodyItemRight: {
        flex: '0.6',
        padding: '5px',
    },
    space: {
        padding: '5px',
    },
    column: {
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    flex_1: {
        flex: 1,
    },
    border_right_none: {
        borderRight: 'none',
    },
    border_right: {
        borderRight: '1px solid black',
    },
    border_top_none: {
        borderTop: 'none',
    },
    border_bottom: {
        borderBottom: '1px solid black',
    },
    margin_bottom: {
        marginBottom: 5,
    },
    dot: {
        width: '5px',
        height: '5px',
        backgroundColor: 'black',
        borderRadius: '5px',
        margin: '4px 5px 0',
    },
    warning: {
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: '700',
        color: 'red',
    },
    kfcParagraphTitle: {
        fontSize: 14,
        lineHeight: '16px',
        fontWeight: 500,
        color: 'black',
    },
    spaceHorizontal: {
        padding: '0 10px',
    },
    margin_top: {
        marginTop: '5px',
    },
    infoViewItem: {
        display: 'flex',
        flex: 1,
        minHeight: '50px',
        alignItems: 'center',
        padding: '0 10px',
    },
    infoViewItemText: {},
    verticalSpace: {
        marginBlock: 25,
    },
    feesTable: {
        display: 'flex',
        margin: '5px',
        border: '1px solid black',
        maxWidth: '400px',
    },
    feesTableContainer: {
        flex: 1,
    },
    feesTableHeaderText: {
        fontSize: '12px',
        lineHeight: '16px',
        fontWeight: '700',
        textAlign: 'center',
        backgroundColor: '#ffe3d3',
        padding: '5px 10px',
        borderBottom: '1px solid black',
        whiteSpace: 'nowrap',
        marginBottom: 0,
    },
    feesTableBody: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '2.5px 0',
    },
    checkBoxMainContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    checkBoxMainContainerKfcParagraph: {
        flex: 1,
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    checkboxItem: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 10px',
    },
    checkboxItemSpan: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '5px',
    },
    checkbox: {
        width: '12px',
        height: '12px',
        border: '1px solid black',
        display: 'flex',
        padding: '1px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    checkboxFill: {
        width: '5px',
        height: '5px',
        backgroundColor: 'black',
    },
};

const KFSTable = ({ feesBrackets, employee }) => {
    
    return (
        <div style={styles.container}>
            <div style={styles.body}>
                <table style={styles.table}>
                    <tbody>
                        <tr>
                            <td>
                                <div style={styles.header}>
                                    <div style={styles.logoContainer}>
                                        <img src="https://kp-statics.s3.me-south-1.amazonaws.com/Ajman_bank_logo.png"
                                            alt="ajman-bank" style={styles.logoContainerImg} />
                                    </div>
                                </div>
                                <div>
                                    <p style={styles.kfcTitle}>KEY FACTS STATEMENT (KFS) – KAMELPAY CARDHOLDERS SALARY ADVANCE FACILITY</p>
                                    <p style={{ ...styles.kfcParagraph, marginBottom: 20 }}>
                                        WARNING: Read this document carefully and sign only if you clearly understand and agree to
                                        the content of the Key Facts Statement (KFS), which is available in English and Arabic. You
                                        may also use this document to compare different Islamic Covered Drawing facility offered by
                                        other Banks. You have the right to receive Key Facts Statement from other Banks for
                                        comparison.
                                    </p>
                                    <div style={styles.tableSection}>
                                        <div style={styles.tableSectionHeader}>
                                            <p style={styles.tableSectionHeaderText}>PRODUCT INFORMATION</p>
                                        </div>
                                        <div style={styles.tableSectionBody}>
                                            <div style={styles.tableSectionBodyItem}>
                                                <div style={styles.tableSectionBodyItemLeft}>
                                                    <p style={styles.kfcParagraph}>
                                                        Product Definition
                                                    </p>
                                                </div>
                                                <div style={styles.tableSectionBodyItemRight}>
                                                    <p style={styles.kfcParagraph}>
                                                        Salary advance facility is based on the Islamic concept “Qard Al Hasan”,
                                                        which refers to “Interest Free Loan”. Qard Al Hasan is a contract between
                                                        two parties (the Bank and the Customer) to fulfill a short-term financial
                                                        need of the borrower (Customer)
                                                    </p>
                                                </div>
                                            </div>
                                            <div style={styles.tableSectionBodyItem}>
                                                <div style={styles.tableSectionBodyItemLeft}>
                                                    <p style={styles.kfcParagraph}>
                                                        Islamic Finance Structure
                                                    </p>
                                                </div>
                                                <div style={styles.tableSectionBodyItemRight}>
                                                    <p style={styles.kfcParagraph}>
                                                        Qard Al Hasan
                                                    </p>
                                                </div>
                                            </div>
                                            <div style={styles.tableSectionBodyItem}>
                                                <div style={styles.tableSectionBodyItemLeft}>
                                                    <p style={styles.kfcParagraph}>
                                                        Types of securities against which Salary Advance is granted
                                                    </p>
                                                </div>
                                                <div style={styles.tableSectionBodyItemRight}>
                                                    <p style={styles.kfcParagraph}>
                                                        Salary Transfer
                                                    </p>
                                                </div>
                                            </div>
                                            <div style={styles.tableSectionBodyItem}>
                                                <div style={styles.tableSectionBodyItemLeft}>
                                                    <p style={styles.kfcParagraph}>
                                                        Pricing
                                                    </p>
                                                </div>
                                                <div style={styles.tableSectionBodyItemRight}>
                                                    <p style={styles.kfcParagraph}>
                                                        Not Applicable
                                                    </p>
                                                </div>
                                            </div>
                                            <div style={styles.tableSectionBodyItem}>
                                                <div style={styles.tableSectionBodyItemLeft}>
                                                    <p style={styles.kfcParagraph}>
                                                        Calculation Methodology
                                                    </p>
                                                </div>
                                                <div style={styles.tableSectionBodyItemRight}>
                                                    <p style={styles.kfcParagraph}>
                                                        Kamel pay card holder will get 50% of the salary amount as a Qard Al Hasan,
                                                        i.e. the amount is calculated after deducting all Cardholder self-declared
                                                        obligations.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.tableSection, ...styles.border_top_none }}>
                                        <div style={styles.tableSectionHeader}>
                                            <p style={styles.tableSectionHeaderText}>FEES & CHARGES</p>
                                        </div>
                                        <div style={styles.tableSectionBody}>
                                            <div style={{ ...styles.tableSectionBodyItem, ...styles.space }}>
                                                <p style={styles.kfcParagraph}>
                                                    To view all our Fees and charges, you may visit our website www.ajmanbank.ae,
                                                    call 800 22, or visit any of our branches to receive a copy.
                                                </p>
                                            </div>
                                            <div style={{ ...styles.tableSectionBodyItem, ...styles.column }}>
                                                <div style={{ ...styles.tableSectionBodyItemLeft, ...styles.border_right_none, ...styles.border_bottom }}>
                                                    <p style={styles.kfcParagraph}>
                                                        Profit Rate
                                                    </p>
                                                </div>
                                                <div style={styles.tableSectionBodyItemRight}>
                                                    <p style={styles.kfcParagraph}>
                                                        Not Applicable.
                                                    </p>
                                                </div>
                                            </div>
                                            <div style={{ ...styles.tableSectionBodyItem, ...styles.column }}>
                                                <div style={{ ...styles.tableSectionBodyItemLeft, ...styles.border_right_none }}>
                                                    <p style={styles.kfcParagraph}>
                                                        Fees
                                                    </p>
                                                </div>
                                            </div>
                                            <div style={styles.tableSectionBodyItem}>
                                                <div style={styles.tableSectionBodyItemLeft}>
                                                    <p style={styles.kfcParagraph}>
                                                        Processing Fees
                                                    </p>
                                                </div>
                                                <div style={styles.tableSectionBodyItemRight}>
                                                    <div style={styles.feesTable}>
                                                        <div style={{ ...styles.feesTableContainer, ...styles.border_right }}>
                                                            <p style={styles.feesTableHeaderText}>
                                                                Salary Advance Amount
                                                            </p>
                                                            <div style={styles.feesTableBody} id="fee-brackets">
                                                                {<MappedElement
                                                                    data={feesBrackets}
                                                                    renderElement={(o, i) => {
                                                                        return <p style={{ ...styles.kfcParagraph, lineHeight: 2 }}> AED {o?.fromAmount} – AED {o?.toAmount} </p>
                                                                    }}
                                                                />}
                                                            </div>
                                                        </div>
                                                        <div style={styles.feesTableContainer}>
                                                            <p style={styles.feesTableHeaderText}>
                                                                Administration Fees (Exclusive VAT)
                                                            </p>
                                                            <div style={styles.feesTableBody} id="fees">
                                                                {<MappedElement
                                                                    data={feesBrackets}
                                                                    renderElement={(o, i) => {
                                                                        return <p style={{ ...styles.kfcParagraph, lineHeight: 2 }}> AED {o?.fees} </p>
                                                                    }}
                                                                />}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ ...styles.tableSectionBodyItem, ...styles.column }}>
                                                <div style={{ ...styles.tableSectionBodyItemLeft, ...styles.border_right_none }}>
                                                    <p style={styles.kfcParagraph}>
                                                        DISCLAIMERS:
                                                    </p>
                                                </div>
                                                <div style={styles.tableSectionBodyItemRight}>
                                                    <div style={styles.row}>
                                                        <div style={styles.dot}></div>
                                                        <p style={{ ...styles.kfcParagraph, ...styles.margin_bottom, ...styles.flex_1 }}>
                                                            All Fees mentioned above and on www.ajmanbank.ae are exclusive of Value
                                                            Added Tax (VAT). A VAT of 5% shall be applicable on all Fees levied by
                                                            the Bank.
                                                        </p>
                                                    </div>
                                                    <div style={styles.row}>
                                                        <div style={styles.dot}></div>
                                                        <p style={{ ...styles.kfcParagraph, ...styles.margin_bottom, ...styles.flex_1 }}>
                                                            Ajman Bank reserves the right to revise the fees & charges and terms &
                                                            conditions, including the profit calculation methodology at any time by
                                                            providing sixty (60) days prior written notice to your registered
                                                            contact details.
                                                        </p>
                                                    </div>
                                                    <div style={styles.row}>
                                                        <div style={styles.dot}></div>
                                                        <p style={{ ...styles.kfcParagraph, ...styles.margin_bottom, ...styles.flex_1 }}>
                                                            If the case is delayed by the Bank, Customers retain the option to
                                                            cancel the contract without cost or penalty before the funds are made
                                                            available.
                                                        </p>
                                                    </div>
                                                    <div style={{ ...styles.tableSectionBodyItem, ...styles.column, ...styles.space }}>
                                                        <p style={styles.kfcParagraph}>
                                                            <span style={styles.warning}>WARNING: </span>
                                                            If you have further clarifications or in case you are not accepting the
                                                            new/modified changes, please reach the nearest branch within the
                                                            specified period or call us on 800 22; otherwise, you will be liable for
                                                            the changes once implemented.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p style={{ ...styles.kfcParagraph, ...styles.verticalSpace, pageBreakBefore: 'always' }}>
                                        Ajman Bank hereby declares that it has been licensed and authorized by the Central Bank of
                                        the UAE to carry out banking business and services. All our products and services are
                                        Shari’ah compliant and approved by our Internal Shari’ah Supervision Committee. For details
                                        on approval, kindly visit www.ajmanbank.ae.
                                    </p>
                                    <div style={styles.tableSection}>
                                        <div style={styles.tableSectionHeader}>
                                            <p style={styles.tableSectionHeaderText}>YOU MUST KNOW</p>
                                        </div>
                                        <div style={styles.tableSectionBody}>
                                            <div style={{ ...styles.tableSectionBodyItem, ...styles.space }}>
                                                <p style={styles.kfcParagraph}>
                                                    Prior to applying for Salary Advance, you should take into account any
                                                    foreseeable future changes to your financial circumstances (such as retirement
                                                    occurring before the end of the finance term). You should only avail the
                                                    facility, if you have financial means to cope up with potential risk that may
                                                    arise from changes in the economic and market conditions as well as changes in
                                                    your circumstances. You may consult your independent financial advisor for
                                                    advice.
                                                </p>
                                            </div>
                                            <div style={{ ...styles.tableSectionBodyItem, ...styles.space }}>
                                                <p style={styles.kfcParagraph}>
                                                    <span style={styles.kfcParagraphTitle}>COOLING-OFF PERIOD : </span>
                                                    Khiyar Al-Shart ‘Cooling-off Period’ is defined as a period of time after a
                                                    contract is agreed during which the buyer can cancel the contract without
                                                    incurring a penalty. Customers may waive the cooling-off period of complete 5
                                                    business days by signing a written waiver provided by Ajman Bank.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.tableSection, ...styles.border_top_none }}>
                                        <div style={styles.tableSectionHeader}>
                                            <p style={styles.tableSectionHeaderText}>APPLICATION AND APPROVAL PROCESS</p>
                                        </div>
                                        <div style={styles.tableSectionBody}>
                                            <div style={styles.tableSectionBodyItemColumn}>
                                                <div style={styles.tableSectionBodyItemRight}>
                                                    <div style={styles.row}>
                                                        <div style={styles.dot}></div>
                                                        <p style={{ ...styles.kfcParagraph, ...styles.margin_bottom, ...styles.flex_1 }}>
                                                            Kamel Pay Cardholders shall request for Ajman Bank- Salary Advance which
                                                            will be obtained through Kamel Pay Mobile Application only.
                                                        </p>
                                                    </div>
                                                    <div style={styles.row}>
                                                        <div style={styles.dot}></div>
                                                        <p style={{ ...styles.kfcParagraph, ...styles.margin_bottom, ...styles.flex_1 }}>
                                                            Card holder will get 50% of the amount as a Qard Hassan, i.e. the amount
                                                            is calculated after deducting all Cardholder’s self-declared
                                                            obligations.
                                                        </p>
                                                    </div>
                                                    <div style={styles.row}>
                                                        <div style={styles.dot}></div>
                                                        <p style={{ ...styles.kfcParagraph, ...styles.margin_bottom, ...styles.flex_1 }}>
                                                            Once transaction is approved, amount is provided in the Cardholder's
                                                            prepaid card, so the Cardholder can withdraw the amount through
                                                            ATM/POS/Remittance/ Bill payment/Any Service of Kamel Pay or Ajman Bank
                                                            to use it to cover any of the Cardholder's monthly financial
                                                            obligations.
                                                        </p>
                                                    </div>
                                                    <div style={styles.row}>
                                                        <div style={styles.dot}></div>
                                                        <p style={{ ...styles.kfcParagraph, ...styles.margin_bottom, ...styles.flex_1 }}>
                                                            An administration fee shall be charged upon availing such service, and
                                                            the fee is deducted from the salary prepaid card.
                                                        </p>
                                                    </div>
                                                    <div style={styles.row}>
                                                        <div style={styles.dot}></div>
                                                        <p style={{ ...styles.kfcParagraph, ...styles.margin_bottom, ...styles.flex_1 }}>
                                                            Salary Advance amount, shall be paid from the following Cardholder’s
                                                            salary transferred to Kamel Pay Prepaid Card.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.tableSection, ...styles.border_top_none }}>
                                        <div style={styles.tableSectionHeader}>
                                            <p style={styles.tableSectionHeaderText}>KEY OBLIGATIONS</p>
                                        </div>
                                        <div style={styles.tableSectionBody}>
                                            <div style={{ ...styles.tableSectionBodyItem, ...styles.column, ...styles.space }}>
                                                <p style={styles.kfcParagraph}>
                                                    <span style={styles.warning}>WARNING : </span>
                                                    In the event of a Customer’s failure to meet Ajman Bank’s terms and conditions
                                                    before and during the relationship, the bank may initiate appropriate action as
                                                    deemed necessary as a consequence of non-repayment or non-fulfillment of signed
                                                    terms and conditions. The actions taken may include the following:
                                                </p>
                                                <div style={{ ...styles.row, ...styles.margin_top }}>
                                                    <div style={styles.spaceHorizontal}>
                                                        <p style={styles.kfcParagraph}>a)</p>
                                                    </div>
                                                    <p style={{ ...styles.kfcParagraph, ...styles.margin_bottom, ...styles.flex_1 }}>
                                                        Negative rating in the Al Etihad Credit Bureau (AECB) or other Credit
                                                        Information agency and the possible limitations on the ability to
                                                        borrow/obtain financing in the future
                                                    </p>
                                                </div>
                                                <div style={styles.row}>
                                                    <div style={styles.spaceHorizontal}>
                                                        <p style={styles.kfcParagraph}>b)</p>
                                                    </div>
                                                    <p style={{ ...styles.kfcParagraph, ...styles.margin_bottom, ...styles.flex_1 }}>
                                                        Legal Proceedings via Court
                                                    </p>
                                                </div>
                                                <div style={styles.row}>
                                                    <div style={styles.spaceHorizontal}>
                                                        <p style={styles.kfcParagraph}>c)</p>
                                                    </div>
                                                    <p style={{ ...styles.kfcParagraph, ...styles.margin_bottom, ...styles.flex_1 }}>
                                                        Collection measures including presenting security cheques for clearing and
                                                        claim on guarantees
                                                    </p>
                                                </div>
                                                <p style={styles.kfcParagraph}>
                                                    In case additional securities in terms of pledge collaterals, guarantors,
                                                    post-dated cheques etc. are obtained, these securities can be utilized or be
                                                    enforced in case of any default in payment or non-fulfillment of any terms and
                                                    conditions of facility offer letter or any other signed contract.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.tableSection, ...styles.border_top_none }}>
                                        <div style={styles.tableSectionHeader}>
                                            <p style={styles.tableSectionHeaderText}>COMPLAINTS & SUGGESTIONS</p>
                                        </div>
                                        <div style={styles.tableSectionBody}>
                                            <div style={{ ...styles.tableSectionBodyItem, ...styles.column, ...styles.space }}>
                                                <p style={styles.kfcParagraph}>
                                                    For complaints and suggestions, you may visit any of our Branches and submit in
                                                    writing or verbally to our customer service officer. You can also call our Phone
                                                    Banking on 800 22 and we will be happy to assist. Alternatively, you may also
                                                    use our website www.ajmanbank.ae or Email address{' '}
                                                    <a href="mailto:info@ajmanbank.ae" style={styles.contactInfoListItemA}>
                                                        info@ajmanbank.ae
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.tableSection, ...styles.border_top_none }}>
                                        <div style={styles.tableSectionHeader}>
                                            <p style={styles.tableSectionHeaderText}>IMPORTANT NOTE FOR THE CUSTOMER AND THE BANK</p>
                                        </div>
                                        <div style={styles.tableSectionBody}>
                                            <div style={{ ...styles.tableSectionBodyItem, ...styles.column, ...styles.space }}>
                                                <p style={styles.kfcParagraph}>
                                                    This Key Facts Statement must be duly signed by the Bank representative and the
                                                    Customer, prior to availing the financial product and/or service. A duplicate
                                                    copy of the signed documents will be provided for your information and records
                                                    at any point of time during the relationship and/or based on your request.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.tableSection, ...styles.border_top_none }}>
                                        <div style={styles.tableSectionHeader}>
                                            <p style={styles.tableSectionHeaderText}>
                                                MARKETING AND PROMOTIONAL - COMMUNICATION
                                            </p>
                                        </div>
                                        <div style={styles.tableSectionBody}>
                                            <div style={{ ...styles.tableSectionBodyItem, ...styles.column, ...styles.space }}>
                                                <div style={{ ...styles.checkBoxMainContainer, marginBottom: 6 }}>
                                                    <p style={styles.kfcParagraph}>
                                                        I agree to receive promotional/marketing/product communication from Ajman
                                                        bank
                                                    </p>
                                                    <div style={styles.checkboxContainer}>
                                                        <div style={styles.checkboxItem}>
                                                            <div style={styles.checkbox}>
                                                                <div style={styles.checkboxFill}></div>
                                                            </div>
                                                            <span style={{ ...styles.kfcParagraph, marginInline: 5 }}>Yes</span>
                                                        </div>
                                                        <div style={styles.checkboxItem}>
                                                            <div style={styles.checkbox}></div>
                                                            <span style={{ ...styles.kfcParagraph, marginInline: 5 }}>No</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={styles.checkBoxMainContainer}>
                                                    <p style={styles.kfcParagraph}>
                                                        Preferred mode of communication (if Yes)
                                                    </p>
                                                    <div style={styles.checkboxContainer}>
                                                        <div style={styles.checkboxItem}>
                                                            <div style={styles.checkbox}></div>
                                                            <span style={{ ...styles.kfcParagraph, marginInline: 5 }}>Email</span>
                                                        </div>
                                                        <div style={styles.checkboxItem}>
                                                            <div style={styles.checkbox}>
                                                                <div style={styles.checkboxFill}></div>
                                                            </div>
                                                            <span style={{ ...styles.kfcParagraph, marginInline: 5 }}>SMS</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ ...styles.tableSectionBodyItem, ...styles.column, ...styles.space }}>
                                                <p style={styles.kfcParagraph}>
                                                    <span style={styles.boldText}>Note:</span>
                                                    If you wish to “Opt in”/”Opt out” of receiving marketing and promotional
                                                    communications, you may call us anytime at 80022.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.tableSection, ...{ pageBreakBefore: 'always' } }}>
                                        <div style={styles.tableSectionHeader}>
                                            <p style={styles.tableSectionHeaderText}>
                                                ACKNOWLEDGEMENT AND SIGNATURE
                                            </p>
                                        </div>
                                        <div style={styles.tableSectionBody}>
                                            <div style={{ ...styles.tableSectionBodyItem, ...styles.space }}>
                                                <p style={styles.kfcParagraph}>
                                                    I hereby acknowledge that I have read, understood, and agree to the content of
                                                    this Key Facts Statement
                                                </p>
                                            </div>
                                            <div style={styles.tableSectionBodyItem}>
                                                <div style={{ ...styles.infoViewItem, ...styles.border_right }}>
                                                    <p style={{ ...styles.infoViewItemText, ...styles.boldText }}>
                                                        Customer Name
                                                    </p>
                                                </div>
                                                <div style={styles.infoViewItem}>
                                                    <p style={styles.infoViewItemText} id="fullname-sec">
                                                        {employee?.displayName}
                                                    </p>
                                                </div>
                                            </div>
                                            <div style={styles.tableSectionBodyItem}>
                                                <div style={{ ...styles.infoViewItem, ...styles.border_right }}>
                                                    <p style={{ ...styles.infoViewItemText, ...styles.boldText }}>
                                                        Emirates ID
                                                    </p>
                                                </div>
                                                <div style={styles.infoViewItem}>
                                                    <p style={styles.infoViewItemText} id="emiratesID-sec">
                                                        {employee?.eid}
                                                    </p>
                                                </div>
                                            </div>
                                            <div style={styles.tableSectionBodyItem}>
                                                <div style={{ ...styles.infoViewItem, ...styles.border_right }}>
                                                    <p style={{ ...styles.infoViewItemText, ...styles.boldText }}>
                                                        Emirates ID Expiry
                                                    </p>
                                                </div>
                                                <div style={styles.infoViewItem}>
                                                    <p style={styles.infoViewItemText} id="emiratesID-expiry-sec">
                                                        {renderDate(employee?.eidExpiry)}
                                                    </p>
                                                </div>
                                            </div>
                                            <div style={styles.tableSectionBodyItem}>
                                                <div style={{ ...styles.infoViewItem, ...styles.border_right }}>
                                                    <p style={{ ...styles.infoViewItemText, ...styles.boldText }}>
                                                        Date
                                                    </p>
                                                </div>
                                                <div style={styles.infoViewItem}>
                                                    <p style={styles.infoViewItemText} id="date">
                                                        {renderDate()}
                                                    </p>
                                                </div>
                                            </div>
                                            <div style={styles.tableSectionBodyItem}>
                                                <div style={{ ...styles.infoViewItem, ...styles.border_right }}>
                                                    <p style={{ ...styles.infoViewItemText, ...styles.boldText }}>
                                                        Mobile Number
                                                    </p>
                                                </div>
                                                <div style={styles.infoViewItem}>
                                                    <p style={styles.infoViewItemText} id="phone">
                                                        {employee?.mobileNo}
                                                    </p>
                                                </div>
                                            </div>
                                            <div style={styles.tableSectionBodyItem}>
                                                <div style={{ ...styles.infoViewItem, ...styles.border_right }}>
                                                    <p style={{ ...styles.infoViewItemText, ...styles.boldText }}>
                                                        Card Number
                                                    </p>
                                                </div>
                                                <div style={styles.infoViewItem}>
                                                    <p style={styles.infoViewItemText} id="cardNumber">
                                                        {employee?.cardNo}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};


const KFSReceipt = (props) => {

    const feesBrackets = props?.feesBrackets;
    const employee = props?.employee;

    return (
        <div style={styles.container} >
            <KFSTable feesBrackets={feesBrackets} employee={employee} />
        </div>
    );
};

export default KFSReceipt;