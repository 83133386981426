import React from "react";
import { CButton } from "../../uiComponents";
import { ConditionalRendering } from "../../utils/methods";
import { useNavigate } from "react-router";
import { IoIosCash } from "react-icons/io";
import { MOBILE_USER_STATUS } from "../../utils/constants";

const CAdvanceSalary = ({ employee, mobileUser, client, canApply }) => {

    const navigate = useNavigate();

    return <>
        <ConditionalRendering
            condition={mobileUser?.status === MOBILE_USER_STATUS?.ACTIVE && !mobileUser?.isLightUser}
            children={<CButton
                containerClass="flex0"
                buttonType="gradient"
                title="Advance Salary"
                onClick={() => navigate('/advance-salary', { state: { employee, client, mobileUser, canApply } })}
                leftIcon={(className) => <IoIosCash className={className} />}
            />}
        />
    </>
};

export default CAdvanceSalary;