import DeliveryModule from "../constants/DeliveryModule.constant";

const initialState = {

    data: {},
    getEmployeeDetailLoading: false,

    clients: [],
    getClientLoading: false,

    getOtpUpdatePhoneNumberLoading: false,

    activateCardLoading: false,

};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case DeliveryModule.GET_DELIVERY_EMPLOYEE:
            return {
                ...state,
                data: action.data,
                getEmployeeDetailLoading: action.loading,
            };

        case DeliveryModule.GET_DEIVERY_CLIENTS:
            return {
                ...state,
                clients: action.data,
                getClientLoading: action.loading,
            };

        case DeliveryModule.GET_OTP_UPDATE_PHONE_NUMBER:
            return {
                ...state,
                getOtpUpdatePhoneNumberLoading: action.loading,
            };

        case DeliveryModule.ACTIVATE_CARD:
            return {
                ...state,
                activateCardLoading: action.loading,
            };

        default:
            return state;
    }
};
