import EMPLOYER from "../constants/Employer.constant";

const initialState = {
    getLoading: false,
    employer: [],
    employersMetaData: {},

    allEmployers: [],

    addEmployeeLoading: false,

    syncLoading: false,

    toggleAclLoading: false,

    dashboard: [],
    getDashoardLoading: false,

    addEstalishmentsLoading: false,

    changePasswordLoading: false,

    uploadEOSLoading: false,

    updateClientDetailsLoading: false,

    constants: [],
    getConstantsLoading: false,

    addUpdateOnboardingSourceLoading: false,
    addUpdateProductLoading: false,
    addUpdateChargesLoading: false,
    addUpdateBusinessOwnerLoading: false,
    addUpdateAdditionalDetailsLoading: false,
    addUpdateCashbackLoading: false,
    addUpdateOperationUserLoading: false,

    singleEmployer: {},
    getSingleEmployerLoading: false,

};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case EMPLOYER.GET_EMPLOYER:
            return {
                ...state,
                getLoading: action.loading,
                employer: action.data || state.employer,
                employersMetaData: action.metaData || state.employersMetaData
            };

        case EMPLOYER.GET_ALL_EMPLOYER:
            return {
                ...state,
                getLoading: action.loading,
                allEmployers: action.data || state.allEmployers,
            };

        case EMPLOYER.ADD_EMPLOYER:
            return {
                ...state,
                addEmployeeLoading: action.loading,
            };

        case EMPLOYER.SYNC_EMPLOYEERS:
            return {
                ...state,
                syncLoading: action.loading,
            };

        case EMPLOYER.TOGGLE_CLIENT_ACL:
            return {
                ...state,
                toggleAclLoading: action.loading,
            };

        case EMPLOYER.GET_EMPLOYER_DASHOARD:
            return {
                ...state,
                dashboard: action.data,
                getDashoardLoading: action.loading
            };

        case EMPLOYER.ADD_ESHTABLISHMENTS:
            return {
                ...state,
                addEstalishmentsLoading: action.loading,
            };

        case EMPLOYER.CHANGE_PASSWORD:
            return {
                ...state,
                changePasswordLoading: action.loading,
            };

        case EMPLOYER.UPLOAD_EOS:
            return {
                ...state,
                uploadEOSLoading: action.loading,
            };

        case EMPLOYER.UPDATE_CLIENT_DETAILS:
            return {
                ...state,
                updateClientDetailsLoading: action.loading,
            };

        case EMPLOYER.GET_EDIT_CONSTANTS:
            return {
                ...state,
                constants: action.data,
                getConstantsLoading: action.loading,
            };

        case EMPLOYER.ADD_UPDATE_ONBOARDING_SOURCE:
            return {
                ...state,
                addUpdateOnboardingSourceLoading: action.loading,
            };

        case EMPLOYER.ADD_UPDATE_PRODUCT:
            return {
                ...state,
                addUpdateProductLoading: action.loading,
            };

        case EMPLOYER.ADD_UPDATE_CHARGES:
            return {
                ...state,
                addUpdateChargesLoading: action.loading,
            };

        case EMPLOYER.ADD_UPDATE_BUSINESS_OWNER:
            return {
                ...state,
                addUpdateBusinessOwnerLoading: action.loading,
            };

        case EMPLOYER.ADD_UPDATE_ADDITIONAL_DETAILS:
            return {
                ...state,
                addUpdateAdditionalDetailsLoading: action.loading,
            };

        case EMPLOYER.ADD_UPDATE_CASHBACK:
            return {
                ...state,
                addUpdateCashbackLoading: action.loading,
            };

        case EMPLOYER.ADD_UPDATE_OPERATION_USER:
            return {
                ...state,
                addUpdateOperationUserLoading: action.loading,
            };

        case EMPLOYER.GET_SINGLE_EMPLOYER:
            return {
                ...state,
                singleEmployer: action.data,
                getSingleEmployerLoading: action.loading,
            };

        default:
            return state;
    }
};
