const DeliveryModuleConstant = {

    GET_DELIVERY_EMPLOYEE: 'GET_DELIVERY_EMPLOYEE',

    GET_DEIVERY_CLIENTS: 'GET_DEIVERY_CLIENTS',

    GET_OTP_UPDATE_PHONE_NUMBER: 'GET_OTP_UPDATE_PHONE_NUMBER',

    ACTIVATE_CARD: 'ACTIVATE_CARD',

};

export default DeliveryModuleConstant;
