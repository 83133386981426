import MOBILE_APPROVALS from "../constants/MobileAppApprovals.constant";

const initialState = {
    getLoading: false,
    approvals: [],
    metaData: {},

    approveRejectLoading: false,

    getSingleApprovalLoading: false,
    singleApproval: {},

    getApprovalApplicables :{},
    getApprovalApplicablesLoading : false ,
    approvalApplicablesMetaData: {},
    loadMoreLoading : false,
    canLoadMore : false,


};

export default (state = initialState, action = {}) => {
    switch (action.type) {

        case MOBILE_APPROVALS.GET_APP_APPROVALS:
            return {
                ...state,
                approvals: action.data,
                getLoading: action.loading,
                metaData: action.metaData
            };

        case MOBILE_APPROVALS.APP_APPROVE_REJECT:
            return {
                ...state,
                approveRejectLoading: action.loading,
            };

        case MOBILE_APPROVALS.GET_SINGLE_APP_APPROVAL:
            return {
                ...state,
                getSingleApprovalLoading: action.loading,
                singleApproval: action.data,
            };
        
        case MOBILE_APPROVALS.GET_APPROVALS_APPLICABLES:
            return {
                ...state,
                getApprovalApplicables: action.data,
                getApprovalApplicablesLoading: action.loading,
                canLoadMore: true,
                loadMoreLoading: false,
                approvalApplicablesMetaData: action.metaData,
            }
        case MOBILE_APPROVALS.LOAD_MORE_APPROVALS_APPLICABLES:
            return {
                ...state,
                getApprovalApplicables: [...state.getApprovalApplicables, ...action.data],
                canLoadMore: action?.canLoadMore,
                loadMoreLoading: action.loading,
                approvalApplicablesMetaData: action.metaData
            };

        default:
            return state;
    }
};
