import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, List, Modal, Skeleton } from "antd";
import { getReferenceInfo } from "../../../../store/actions/FindCardHolder.action";
import InfiniteScroll from "react-infinite-scroll-component";
import { FaRegUserCircle } from "react-icons/fa";

const ReferredToOther = ({ isOpen, setIsOpen, employeeId, clientId, referencePhone }) => {

    const dispatch = useDispatch();

    const reduxState = useSelector(({ findCardHolder }) => {
        return {
            data: findCardHolder?.referenceInfo,
            loading: findCardHolder?.getReferenceInfoloading,
            metaData: findCardHolder?.referenceInfoMetaData,
            loadMoreLoading: findCardHolder?.referenceLoadMoreLoading,
            canLoadMore: findCardHolder?.referenceCanLoadMore,
        };
    });

    const { data = [], loading, metaData, loadMoreLoading, canLoadMore } = reduxState;

    const [page, setPage] = useState(1);
    const [limit] = useState(20);

    useEffect(() => {
        if (isOpen) {
            get(page);
        }
    }, [isOpen, page]);

    const get = (val) => {
        const payload = {
            page: val || 1,
            limit,
            employeeId,
            client: clientId,
            referencePhone,
        };
        dispatch(getReferenceInfo(payload));
    };

    const next = () => {
        if (metaData?.totalDocuments > data?.length) {
            setPage(page + 1)
        }
    };

    return (
        <Modal
            title='Refered to other users'
            width={450}
            centered
            open={isOpen}
            destroyOnClose={true}
            maskClosable={false}
            onCancel={() => !loading && setIsOpen(false)}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
        >
            <InfiniteScroll
                className={`infinite-scroll ${loading && 'loading'}`}
                dataLength={data?.length || 0}
                next={next}
                height={400}
                hasMore={canLoadMore}
                loader={loadMoreLoading && canLoadMore && <Skeleton className="margin-top_15 margin-left_20"
                    avatar
                    round={false}
                    paragraph={{ rows: 0 }}
                    active
                />}
            >
                <List
                    dataSource={data}
                    loading={loading}
                    loadMore={loadMoreLoading}
                    className="ant-list-item-countries"
                    renderItem={(item) => <List.Item key={item?._id} >
                        <List.Item.Meta
                            avatar={<Avatar shape="square" className="avatar-container" src={<FaRegUserCircle className="c-icon xx-large secondary margin_zero" />} />}
                            title={item?.user?.fullName}
                            description={item?.user?.phone}
                        />
                    </List.Item>
                    }
                />
            </InfiniteScroll>
        </Modal>
    );
};
export default React.memo(ReferredToOther);